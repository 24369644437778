.gs-blockquote-card {
    background: $testimonial-card;
    padding: 2.8rem 1.5rem 2rem 1.5rem;
    color: #1c1c1c;
    border-radius: 4px;
    @include bp("xl") {
        margin: 0 .5rem;
    }
    &__text {
        @extend .fnt-lg;
        font-style: italic;
    }
}

.gs-quote {
    position: absolute;
    // top: 0;
    // transform: translateY(-50%);
    
    img{
        height: 30px;
        width: 30px;
        
        @include bp("md") {
            height: 35px;
            width: 35px;
        }
    }
    p {
        color: #0079f5;
        font-family: Poppins;
        font-size: 160px;
        font-weight: 400;
    }
}


.img50x50 {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.author-info {
    margin-top: 1.5rem;
}

.gs-author-name {
    font-size: 12px;
    line-height: 22px;
    font-weight: $fnt-semi;
}

.gs-author-qf {
    font-size: 10px;
    line-height: 20px;
}