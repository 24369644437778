.gs-back-head-d {
    @extend .fnt-sm;
    font-weight: $fnt-semi;
    margin-bottom: 2rem;

    span {
        display: inline-flex;
        align-items: center;
        color:  #00acf5;
    }
}

.angle-rotate-d {
    display: inline-flex;
    transform: rotate(90deg);
    @extend .fnt-xs;
    margin-right: .5rem;
}
.angle-rotate-left {
    display: inline-block;
    transform: rotate(90deg)translateX(-1px);
    @extend .fnt-xs;
    margin-right: .5rem;
}
.angle-rotate-right {
    display: inline-block;
    transform: rotate(-90deg) translateX(1px);
    @extend .fnt-xs;
    margin-left: .5rem;
}

.angle-rt27-d {
    transform: rotate(270deg);
    display: inline-flex;
    position: relative;
    top: 1px;
    font-size: 12px;
    margin-left: .4rem;
}

// buttons

.btn-sudo-arrow {
    position: relative;
}

// Form fileds 

#addjob-form .steps-form {
    position: relative;
    &:not(:first-of-type) {
        display: none;
    }
}

.form-group-d {
    margin-bottom: 2.2rem !important;
}

.ui.form textarea:not([rows]) {
    height: 7em !important;
    min-height: 7.4em !important;
}

.gs-dropdown-group {
    display: flex;
    align-items: center;

    span {
        width: 100px;
        margin-left: 1rem;
    }
}

.info-text {
    font-size: 10px;
    line-height: 15px;
    display: inline-block;
    color: #131313;
    margin-right: 1rem;
    margin-top: .4rem;
}

// Review and approve 

.gs-job-container {
    display: flex;
    justify-content: space-between;
}

.job-label {
    font-size: 10px;
    line-height: 20px;
    font-weight: $fnt-reg;
}

.job-text {
    @extend .fnt-sm;
    letter-spacing: 0.58px;
    font-weight: $fnt-medium;
}

.gs-hiring-team {
    display: flex;
    align-items: center;
    z-index: 1;
    position: relative;
    &__profile {
        margin-right: -.6rem;
        z-index: 10;
        &:last-child {
            margin-right: -.5rem;
        }
    }
}

.img26x26 {
    height: 26px;
    width: 26px;
    border-radius: 50%;
}

.gs-memebr-count {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: $white;
    @extend .fnt-xxs;
    color: #494949;
    @extend .flex-center;
    font-weight: $fnt-medium;
    z-index: -1;
}

// Hiring description

.gs-hiring {
    h4 {
        @extend .fnt-md;
        font-weight: $fnt-semi;
        letter-spacing: 0.67px;
    }
}

.gs-job-quali-container {
    display: flex;
    flex-wrap: wrap;
}

.gs-job-quali-info {
    margin-top: 3rem;
}

.gs-job-quali-categ {
    @extend .fnt-sm;
    margin-right: 2rem;
    margin-top: .8rem;

    &__label {
        font-weight: $fnt-medium;
        width: 86px;
        display: inline-flex;
        justify-content: space-between;
        margin-right: .5rem;
    }
}