.ui.form input[type=text], 
.ui.form input[type=url], 
.ui.form textarea,
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="time"],
.ui.form input[type="file"],
.ui.form input[type="url"],
.ui.form input[type="number"],
.ui.form input[type="date"],
.ui.form input[type=date] {
    
    font-family: 'Poppins', sans-serif !important;
    font-weight: $fnt-reg;
    font-size: 12px;
    line-height: 24px;
    border: 1px solid #b4b4b4;
}


.ui.form .field.error label {
    color: $black;
}

// error state

.ui.form .field .prompt.label {
    border: none !important;
    color: #de3940 !important;
    @extend .fnt-xs;
    margin-top: 2px;
}

.ui.form .field.error input[type=text] {
    background: transparent;
    border-color: #de3940 !important;
}


// Dropdown and input filleds

.ui.fluid.dropdown {
    height: 48px !important;
    padding: .8rem;
}
.ui.search.selection.dropdown > input.search {
    padding: .8rem !important;
    cursor: pointer;
}

.ui.selection.dropdown .menu {
    padding-bottom: 1rem;
    margin-top: 1px;
}

.ui.selection.dropdown .menu>.item, .ui.dropdown .menu>.item:hover {
    border: none;
    background: transparent;
}

.ui.selection.dropdown .menu>.item {
    @extend .fnt-sm;
    font-weight: $fnt-medium;
    padding-bottom: 0 !important;
}

.ui.dropdown .menu>.item:hover {
    &:hover, &:focus {
        background: transparent;
    }
}

.ui.dropdown .menu .selected.item {
    font-weight: $fnt-semi;
}
.ui.selection.dropdown .menu>.item:hover,
.gs-country-item:hover  {
    background-color: #e4f7ff;;
      
}
// Country dropdown

.gs-country-item, .gs-coutry-text {
    display: flex !important;
    align-items: center;
    justify-content: space-between;

    
}

.gs-country-item .flag {
    margin-right: 0 !important;
}

.gs-coutry-text .flag{
    margin-right: 1.2rem !important;
}