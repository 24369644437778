@media screen and (-ms-high-contrast:active),
(-ms-high-contrast:none) {
    .candidate-notes__container {
        width: 100%;
    }
    .candidate-notes__head {
        display: table-cell;
    }
    #banner-video {
        height: auto !important;
    }
    .multiselect-container {
        border: 0 !important;
    }
    .dropdown-toggle {
        height: 30px !important;
    }
    .profile-ie {
        .msg-wrp {
            margin-top: 32px;
        }
    }
    .main-container {
        overflow-x: hidden;
    }
    // flat-ficker

    .flatpickr-months .flatpickr-next-month svg, .flatpickr-months .flatpickr-prev-month svg {
        margin:0 !important;
    }

    .numInputWrapper span.arrowUp, .numInputWrapper span.arrowDown {
        right: -10px !important;
    }

    .dashboard-ov .profile-info .profile-img {
        display: inline-table;
    }
    .dashboard-ov .profile-details {
        width: 100%;
    }

    // side-sticky
    .sticky-left {
        .gs-side-filter {
            position: fixed;
            top: 6rem;
            // left: 0;
            min-width: 289px;
        }
        .gs-side-filter--sm {
            min-width: 230px !important;
            width: 230px !important; 

        }
    }

    .dz-default.dz-message.needsclick {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

    }
    // Dashboard

    .gs-dashboard-form {
        .sticky-left {
            .gs-side-filter {
                min-width: 240px;
            }
        }
    }
}