
.section-padding10 {
    padding: 3.8rem 0;
    @include bp("md") {
        padding: 6rem 0;
    }
    @include bp("lg") {
        padding: 8rem 0;
    }
    @include bp("xl") {
        padding: 10rem 0;
    }
}

.section-padding7 {
    padding: 4rem 0;
    @include bp("md") {
        padding: 4rem 0;
    }
    @include bp("lg") {
        padding: 7rem 0;
    }
}

.section-padding4 {
    padding: 2.8rem 0;
    @include bp("md") {
        padding: 4rem 0;
    }
    @include bp("lg") {
        padding: 4.5rem 0;
    }
}