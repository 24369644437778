.gs-faq-filterselected {
    .dropdown-toggle::after {
        display: none !important;
    }
    
    .btn-none {
        width: auto;
        min-height: auto !important;
        border: none;
        padding: 0;
    }

    .multiselect-selected-text {
        position: absolute;
        height: 13px;
        width: 13px;
        background: #de3940;
        color: $white;
        border-radius: 50%;
        padding-left: 1px;
        @extend .flex-center;
        top: -4px;
        right: -4px;
        font-size: 8px;
    }

    .multiselect-container {
        // transition: 0 !important;

        @include bp("mobile") {
            top: 1rem !important;
            left: -.5rem !important;
        }
    }

}

#gs-filter {
    @include bp("mobile") {
        height: 22px;
        width: 22px;
    }
}