.tbl-view {
    
    @media screen and (max-width: 1280px) {
        overflow-x: scroll;
        width: 100%;
        
    }
    .table-thread {
        max-width: 1093px !important;
    }
    .date-tab  {
        width: 149px;
        min-width: 149px;
    }
    .bg-red {
        background-color: #feeeee !important;
       
    }
    .bg-blue {
        background-color: #f0f8ff;
    }
    th {
        padding: 20px 15px;
    }
    .hiring-team {
        min-width: 106px;
        width: 108px;
    }
   
    .tbl-head {
        color: #121212;
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        background-color: #f9fafb;
    }
    .tbl-sub {
        width: 230px;
        color: rgba(0, 0, 0, 0.85);
        // font-family: "Poppins Semi Bold";
        font-size: 14px;
        font-weight: 600;
        line-height: 26px;
    }
    // .tbl-sub.active {
    //     position: relative;
    //     &::after {
    //         top: 0;
    //         right: 0;
    //         position: absolute;
    //         content: '';
    //         width: 12px;
    //         height: 11px;
    //         background-image: url("./img/view-jobs/start-gold.svg");
    //     }
    // }
    .tbl-txt {
        color: rgba(0, 0, 0, 0.85);
        // font-family: "Poppins Semi Bold";
        font-size: 14px;
        
        line-height: 24px;
        font-weight: 500;
    }
    .info {
        color: rgba(0, 0, 0, 0.85);
        // font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
    }
    .tbl-body {
        box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
        border-radius: 14px 15px 8px 8px;
        background-color: #ffffff;
        td {
            padding: 20px 12px;
            
        }
        td.category{
            min-width: 170px;
        }
        tr td:first-child {
            padding-left: 18px ;
        }
        tr td:last-child {
            padding-right: 18px ;
        }
        tr {
            position: relative;
            border-bottom: 1px solid #f5f5f5;
            // transform: scale(1);

            transition: all ease-out .8s;

        }
        tr:first-child td:first-child { border-top-left-radius: 14px !important;}
        tr:first-child td:last-child { border-top-right-radius: 15px !important; }
        tr:last-child td:first-child { border-bottom-left-radius: 8px !important; }
        tr:last-child td:last-child { border-bottom-right-radius: 8px !important; }
        tr:hover {
            // z-index: 9999;
            // transform: translate3D(0,1px,0) scale(1.01);
            transition: all ease .3s;
         
            box-shadow: 0 2px 17px 3px rgba(9, 9, 9, 0.1);
       
            .tbl-sub {
                // color: #00aeef;
            }
            .img-wrp {
                z-index: 999;
            }
            // .response span {
            //     color: #00aeef;

            // }
        }
        
      
        
        .fav-icon {
            margin-right: 18px;
            height: 18px;
            width: 14px;
        }
        .location-icon {
            width: 9px;
            height: 12px;
        }
        .comments-wrp {
            span {
                min-width: 149px;
                width: 167px;
                margin-right: 18px;
                
            }
        }
        
    }
    .btn-info {
        border-radius: 2px;
        color: #ffffff;
        background-color: #49a0ee;
        // font-family: "Poppins Medium";
        font-size: 9px;
        font-weight: 500;
        padding: 0px 8px;

        &__exp {
            background-color: #ff696e;
        }
    }
}

.tab-txt {
    color: #4d4d4d;
    // font-family: "Poppins Medium";
    font-size: 14px;
    font-weight: 500;
}
.tab-txt.active {
    position: relative;
    font-weight: 600;
    color: #00acf5;

    &::after {
        position: absolute;
        content: '';
        width: 70px;
        height: 3px;
        border-radius: 5px;
        background-color: #00acf5;
        bottom: -18px;
        left: -10px;
    }
}
.tab-section {
    @media screen and (max-width: 1024px){
        flex-direction: column;
        
    }
    padding : 40px 0 16px 0;
    border-bottom: 1px solid #eff0f1;
    &__tabs {
        @media screen and (max-width: 1024px){
            margin-top: 1rem;
            order: 2;
            
        } 
    }
    .btn-wrp {
        @media screen and (max-width: 1024px){
           
            order: 1;
            
        }
    }
}
.tab-section {
    input.table-search-input:focus~ .job-search {
        opacity: 1;
     
    }
    .job-search {
            cursor: pointer;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            
            @include bp("mobile") {
                margin-right: .8rem;
                right:  0;
                height: 15px;
                width: 15px;
            }
            
            @include bp("md") {
                right: 0;
                margin-right: 1rem;
            }
        
    
    }
    .filter-input {
        min-height: 40px !important;
        position: relative;
        margin-left: 26px;
        padding: 8px 12px;
        
    }
    .filter-badg   {
        line-height: 1.3;
        transform: translate(50%,-50%);
        position: absolute;
        top: 0;
        right: 0;
        background-color: #00aeef;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        color: #ffffff;
        // font-family: "Poppins Semi Bold";
        font-size: 11px;
        font-weight: 600;

        &__tab {
            top: 50% !important;
            line-height: 1.4;
            right: 3px;
        }
        &__filter   {
            line-height: 1.4;
        }
    }

    .nav-item {
        display: flex;
        align-items: flex-end;
    }
    .btn-wrp {
        .table-search-input {
            height: 40px !important;
            border: 2px solid rgba(100, 100, 100, .6)!important;
            background-color: transparent !important;
            padding-left: 2.2rem !important;
            @extend .fnt-xxs;
        }
        .gs-input-search {
            .form-input {
                padding-left: 35px;
                
            }
            .gs-input-search__icon {
                right: inherit;
                left: 11px;

            }
        }
    
    }

    
    .disabled {
        .arrow {
            border: solid #e2e2e2;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;

        }

    }
    .arrow {
        border: solid #5c5c5c;
        border-width: 0 2px 2px 0 !important;
        display: inline-block;
        padding: 3px;
      }
      .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
      
      .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
    
}
.filter-txt {
    color: #4f4f4f !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 2;
}

.paggi-txt {
    color: #4d4d4d;
// font-family: "Poppins Medium";
    font-size: 12px;
    font-weight: 500;
}

.head-h1 {
    color: #393939;
    font-size: 28px;
    font-weight: 600;
}
.team-member-info {
    .img-wrp {
        height: 28px;


        .team-img {
            // position: absolute;
            margin-left: -8px;
            width: 27px;
            height: 28px;
            border: 1px solid #ffffff;
            border-radius: 50%;
            background-color: #e4e4e4;
            .cout {
                color: #494949;
                // font-family: "Poppins Medium";
                font-size: 10px;
                font-weight: 500;
              
            }
         
        }
        
        .team-img:nth-child(1) {
            z-index: 4;
        }
        .team-img:nth-child(2) {
            z-index: 3;
            left: 15px;
        }
        .team-img:nth-child(3) {
            z-index: 2;
            left: 25px;
        }
        .team-img:nth-child(4) {
            z-index: 1;
            left: 45px;
        }
    }
    .img-wrp {
        z-index: 0;
        .team-name {
            color: #152935;
            font-size: 12px;
            font-weight: 600;
        }
        .location {
            color: #152935;
            font-size: 11px;
            font-weight: 400;
            line-height: 1;
        }
     

        .team-member {
            padding: 15px;
            border-bottom: 1px solid #f5f5f5;
            .team-img {
                margin-right: 10px;
            }
        }
        .team-img-wrp {
            opacity: 0;
            // display: none;
            visibility: hidden;
            position: absolute;
            z-index: 9999;
            right: 0;
            top: 37px;
            width: 182px;
            box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.11);
            background-color: #ffffff;
            border-radius: 5px;
            transition: all ease-in-out .8s;

        }
        
    }
    .team-img-wrp::after {
        position: absolute;
        content: '';
        top: -5px;
        right: 20px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #ffffff;
        // box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.11);

    }
    &:hover, &:focus {
        
        .team-img-wrp {
            opacity: 1;
            // display: block;
            visibility: visible;
            transition: all ease-in-out .8s;
            
        }
        .dots-info {
            display: none;
        }
    }
}
.hiring-team-img-wrp {
    cursor: pointer;
    position: relative;
}
.dot-info-wrp {
    position: relative;
    .dots {
        position: relative;
        width: 5px;
        height: 21px;
        cursor: pointer;
    }
}

.dots-info {
    display: none;
    top: 35px;
    right: -10px;
    width: max-content;
    box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.11);
    background-color: #ffffff;

    position: absolute;
    z-index: 150;
    &__item {
        display: flex;
        padding: 8px 12px;
        align-items: center;
        &:hover {
            background-color: #e4f7ff;
        }
    }
    .dot-txt {
        text-transform: capitalize;
        padding-left: 12px;
        color: #686868;
        // font-family: "Poppins Medium";
        font-size: 12px;
        font-weight: 500;
    }
    .edit-icon {
        width: 15px;
        height: 16px;
    }
    .close-icon {
        width: 15px;
        height: 15px;
    }
    &::after {
        position: absolute;
        content: '';
        top: -5px;
        right: 10px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #ffffff;
        // box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.11);

    }

}
.hirig-team-draft {
    position: relative;
    margin-right: 50px;
}
.search-input-field::-ms-clear {
    display: none;
}

//ie 
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .tbl-view .tbl-body tr:hover {
        
        // z-index: 9999;
        // transform: translate3D(0,1px,0) scale(1.01);
        transition: all ease .4s;
        // box-shadow: 0 2px 17px 3px rgba(0, 0, 0, 0.09);
        box-shadow: 0 2px 17px 3px rgba(9, 9, 9, 0.1);
        background-color: #ffffff;
        .tbl-sub {
            color: #00aeef;
        }
        .img-wrp {
            z-index: 999;
        }
        .response span {
            color: #00aeef;

        }
    }
    }
    .btn-reset {
        cursor: pointer;
        color: #2485e8;
        font-size: 12px;
    }