.card {
  cursor: pointer;
  margin-bottom: 0.5rem;
  padding: 0.8rem 0.8rem;
  @include bp("md") {
    padding: 1rem 1.5rem;
  }
  &--primary {
    background-color: rgba(48, 146, 244, 0.09);
    border-radius: $brder-radius4;
    // @include bp("mobile") {
    //     padding: 1rem;
    // }
  }

  // Secondary accordian
  &--secondary {
    background-color: rgba(140, 140, 140, 0.09);
    border-radius: $brder-radius4;
    padding: {
      top: 1rem;
      bottom: 1rem;
    }
    @include bp("md") {
      padding: {
        top: 1.8rem;
        bottom: 1.8rem;
      }
    }
    .gs-card-heading {
      color: #060606;
      @include bp("mobile") {
        font-size: 14px;
        line-height: 24px;
      }
    }
    .card-body {
      padding-left: 1.8rem;
      color: rgba(0, 0, 0, 0.85);
      @include bp("mobile") {
        margin-top: 0.6rem;
        font-size: 12px;
        line-height: 22px;
      }
      @include bp("md") {
        padding-left: 1.5rem;
      }
    }
  }

  &-body {
    padding-right: 1rem;
    margin-top: 0.6rem;
    @extend .fnt-md;
    @include bp("mobile") {
      margin-bottom: 0.5rem;
    }
    @include bp("md") {
      margin-top: 1rem;
      padding-right: 2rem;
    }
    @include bp("xl") {
      padding-right: 3rem;
    }
  }
}

// ONly mobile

.img241x136 {
  @include bp("mobile") {
    height: 160px;
    // width: 241px;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}

.angle-arrow {
  position: relative;
  &::after {
    content: "\68";
    font-family: "giis";
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    transform: rotate(-180deg);
    transition: all 0.6s ease;
    color: #196ec1;
    font-weight: 600;
  }
}

.gs-plusminus {
  position: relative;
  padding-left: 1.8rem;
  @include bp("md") {
    padding-left: 1.5rem;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: #4fc8f6;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 4px;
  }

  &::before {
    left: 0;
    height: 3px;
    width: 14px;
  }

  &::after {
    height: 14px;
    width: 3px;
    left: 5.2px;
    opacity: 0;
  }
}

.gs-card-heading {
  @extend .fnt-lg;
  font-weight: $fnt-semi;

  .collapsed {
    font-weight: $fnt-medium;

    &.angle-arrow::after {
      transform: rotate(0deg);
      transition: all 0.6s ease;
    }

    &.gs-plusminus::after {
      opacity: 1;
    }
  }
}

// Accordian fade in effect for images

.location-accordian {
  .collapse {
    opacity: 0;
    height: auto !important;
    transition: height 0.2s, opacity 0.6s;
    overflow: hidden;
  }
  .collapse.show {
    opacity: 1;
  }
  .collapsing {
    opacity: 0;
    height: auto !important;
  }
}
