$font-poppins:  'Poppins',sans-serif;

@mixin fonts($size, $weight, $color, $family) {
    font-size: $size;
    font-weight: $weight;
    font-family: $family;
    color: $color;
}

.font-14{
    font-size: 14px;
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
}

.font-12{
    font-size: 12px !important;
    font-family: 'Poppins',sans-serif !important;
    font-weight: 500 !important;


}

// .fnt-12-md{
//     font-size: 12px !important;
//     font-family: 'Poppins',sans-serif !important;
//     font-weight: 400 !important;
//     color: red !important;
// }

.pro-img{
    width: 80px;
    height: 80px;
    margin-right: 30px;
}

.p-10{
    padding-left: 2% !important;
    padding-right: 2% !important;
}

.location{
    margin-right: 5px;
}

.profile-btns{
    color: #1a82eb;
    text-transform: none;
    padding: 10px 20px !important;
    margin-right: 20px;
    background: #fff;
    justify-content: space-between;
}

.edit-job .edit-job-container {
    max-width: 100% !important;
    width: 100% !important;
    color: #fff;
}

.sub-text{
    font-size: 13px !important;
    font-weight: 500;
}
.btn-container{
    float: right;
}

.profile-top{
    justify-content: space-between;
}

.p-lr{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.text-blue{
    font-size: 14px;
    color: #2485e8;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 30px;
    
}

.profile-form-label{
    font-size: 12px !important;
    color: #b3b3b3 !important;
    font-weight: 500 !important;
    font-family: 'Poppins',sans-serif;
    margin-bottom: 10px;
}

.profile-form-input{
    background: #f9f9f9 !important;
    border-color: #f9f9f9 !important;
}
.profile-form-input, .profile-drop-plc{
    color: #1d1d1d !important;
    font-weight: 500 !important;
    font-size: 14px !important;

    
}

.dashed-border-bottom{
    border-bottom: 1px dashed #eaeaea !important;
}


.profile-checkbox-label{
    color: #000;
    font-size: 14px;
    font-weight: 500;
}
.label-12{
    font-size: 12px !important;
}

.vertical-center{
    vertical-align: center !important;
}

.w-50{
    width: 50%;
    margin-top: 5px !important;
}

.dropdown-icon{
    color: #00adf2 !important;
}

.r-5{
    margin-right: 10px;
}

.b-transp{
    background: transparent !important;
    border: none !important;
}
.c-white{
    color: white !important;
}

.v-center{
    vertical-align: center !important;

}


.l-5{
    margin-left: 3px;
}

.fnt-black-500{
    font-family: 'Poppins',sans-serif;
    color: #131313;
    font-weight: 500;

}

.file-size{
   color:  #6b6e71;
   font-size: 10px;
   font-family: 'Poppins',sans-serif;
   font-weight: 500;
    &--msg{
        background: #717171;
    }

}

.file-upload{
    background: #f0f7ff;
    border-radius: 8px;
    border-color: #f0f7ff;
    padding: 5%;
}

.w-fc{
        width: fit-content !important;
        margin-right: 10px;

}

.align-vc{
    vertical-align: middle;
}

.font-med{
    color: #131313 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.dropdown-border{
    border-radius: 5px !important;
    border-color: transparent !important;
}

.w-40{
    width: fit-content;
    padding: 10px 20px !important;
    margin-right: 20px;
}

.files{
    flex-direction: row;
}

.file-name{
    @include fonts(12px, 500, #131313, $font-poppins);
}
.bg-transparent{
    background: transparent !important;
    border-color: transparent !important;
}

.cv-drop-text{
    @include fonts(12px, 500, #686868, $font-poppins);
}

.styled-checkbox-cand {
    position: absolute;
    opacity: 0;

}
    
.styled-checkbox-cand+label {
    position: relative;
    padding: 0;
    color: $text-primary85;
    font-weight: 400;
}
    
.styled-checkbox-cand+label:before {
    content: '';
    margin-right: 0 !important;
    display: inline-block;
    vertical-align: text-top;
    width: 15px !important;
    height: 15px !important;
    background: 0 0;
    margin-top: 2px;
    border: 1.5px solid #00aeef !important;
    border-radius: 2px !important;
}

.styled-checkbox-cand:checked+label:before {
    background: #ffffff !important;
}
    
.styled-checkbox-cand:checked+label:after {
    content: '';
    position: absolute;
    left: 6px !important;
    top: 5px !important;
    width: 3px;
    height: 7px;
    border: solid #00aeef;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
}

.candidate-profile-side-sticky {
    li {
        cursor: pointer;
    }
}
.w-170 {
    width: 170px;
}

