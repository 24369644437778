// heading info

h3 {
    font-size: 24px;
    line-height: 34px;
    font-weight: $fnt-bold;

    @include bp("md") {
        font-size: 34px;
        line-height: 44px;
    }
   
    @include bp("lg") {
        font-size: 42px;
        line-height: 50px;
    }
    
    @include bp("xl") {
        font-size: 48px;
        line-height: 58px;
    }
}

.h4 {
    font-size: 24px;
    line-height: 34px;
    font-weight: $fnt-bold;
}

.fnt-xxs {
    font-size: 10px;
    line-height: 20px;
}

.fnt-xs {
    font-size: 12px;
    line-height: 22px;
}

.fnt-sm {
    font-size: 12px;
    line-height: 22px;
    
    @include bp("lg") {
        font-size: 14px;
        line-height: 24px;
    }
}

.fnt-md {
    font-size: 14px;
    line-height: 24px;
    
    @include bp("lg") {
        font-size: 16px;
        line-height: 26px;
    }
}

.fnt-lg {
    font-size: 16px;
    line-height: 26px;

    @include bp("lg") {
        font-size: 18px;
        line-height: 28px;
    }
}


.gs-title {
    font-size: 16px;
    line-height: 26px;
    font-weight: $fnt-reg;
    
    @include bp("md") {
        font-size: 20px;
        line-height: 30px;
    }
    
    @include bp("lg") {
        font-size: 24px;
        line-height: 34px;
    }
    
}

// colors

.heading-clr {
    color: $txt-heading;
}

.black85 {
    color: $text-primary85;
}

// dashboard text

.h5 {
    font-size: 20px;
    line-height: 30px;
    font-weight: $fnt-semi;
    letter-spacing: 0.83px
}
