.gs-jobdes-panel {
    border-radius: 8px;
    padding: 1.5rem 1.5rem;
    @include bp("md") {
        padding: 1.5rem 2rem;
    }

    &__heading {
        font-weight: $fnt-semi;
        font-size: 20px;
        line-height: 30px;
        
        @include bp("md") {
            font-size: 30px;
            line-height: 40px;
        }
    }
    &__text {
        max-width: 380px;
        letter-spacing: 0.5px;
        line-height: 20px;
        @include bp("md") {
            line-height: 22px;
        }
    }
    .location-icon {
        width: 14px;
        height: 17px;
    }
    .notification-icon {
        width: 14px;
        height: 14px;
    }
    .amt-icon {
        width: 18px;
        height: 18px;
    }
}

.gs-joblocation-info {
    display: flex;
    @extend .fnt-md;
    font-weight: $fnt-semi;
    margin-top: .8rem;

    @include bp('tablet') {
        flex-direction: column;
        font-size: 12px !important;
        line-height: 24px !important;
    }

    @include bp("lg") {
        align-items: center;
    }
}

.h4 {
    font-size: 18px;
    line-height: 30px;
    font-weight: $fnt-semi;
    @include bp("md") {
        font-size: 24px;
        line-height: 34px;
    }
}


// Content info
.gs-profile-info {
    
    h4 {
        @extend .h4;
        margin-bottom: .5rem;
        margin-top: 2rem;
        @include bp("md") {
            margin-bottom: 1.2rem;
            margin-top: 3rem;
        }
    }

    p {
        line-height: 26px;
        margin-bottom: 1rem;
        @include bp("md") {
            line-height: 32px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        @extend .gs-block-list;
        padding-top: 0;

        li {
            @extend .gs-block-list__item;
            margin-bottom: .6rem;
            @include bp("md") {
                margin-bottom: 1rem;
            }
        }
    }

    &--das {
        font-weight: $fnt-reg;
        padding-bottom: 1rem;
        
        h4 {
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.67px;
            margin-bottom: 1rem;
            margin-top: 2.5rem;
        }

        p, .gs-read-btn {
            @extend .fnt-sm;
            line-height: 26px;
        }

        ul li {
            font-size: 14px !important;
            line-height: 26px !important;
        }
    }

}

.text{
    display:none;
}

.gs-read-btn {
    @extend .fnt-md;
    font-weight: $fnt-semi;
    cursor: pointer;
    margin-top: .5rem;
    // display: inline-block;
    color: #00adf2 !important;
    // position: relative;
    // padding-left: .8rem;
    // &::before {
    //     content: "+";
    //     position: absolute;
    //     left: 0;
    // }
    // &.less {
    //     &::before {
    //         content: "-";
    //     }
    // }
}

.hideContent {
    transition: all ease-in-out 0.3s;
    display: none;
}

.hideAll {
    transition: all ease-in-out 0.3s;
    display: block;
}

//job application 
#ui-datepicker-div {
	display: none;
	background-color: #fff;
	box-shadow: 0 0.125rem 0.5rem rgba(0,0,0,0.1);
	margin-top: 0.25rem;
	border-radius: 0.5rem;
	padding: 0.5rem;
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    .ui-datepicker-calendar thead th {
        padding: 0.25rem 0;
        text-align: center;
        font-size: 0.75rem;
        font-weight: 400;
        color: #78909C;
    }
    .ui-datepicker-calendar tbody td {
        width: 2.5rem;
        text-align: center;
        padding: 0;
    }
    .ui-datepicker-calendar tbody td a {
        display: block;
        border-radius: 0.25rem;
        line-height: 2rem;
        transition: 0.3s all;
        color: #546E7A;
        font-size: 0.875rem;
        text-decoration: none;
    }
    .ui-datepicker-calendar tbody td a:hover {	
        background-color: #E0F2F1;
    }
    .ui-datepicker-calendar tbody td a.ui-state-active {
        background-color: #009688;
        color: white;
    }
    .ui-datepicker-header a.ui-corner-all {
        cursor: pointer;
        position: absolute;
        top: 0;
        width: 2rem;
        height: 2rem;
        margin: 0.5rem;
        border-radius: 0.25rem;
        transition: 0.3s all;
    }
    .ui-datepicker-header a.ui-corner-all:hover {
        background-color: #ECEFF1;
    }
    .ui-datepicker-header a.ui-datepicker-prev {	
        left: 0;	
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
        background-repeat: no-repeat;
        background-size: 0.5rem;
        background-position: 50%;
        transform: rotate(180deg);
    }
    .ui-datepicker-header a.ui-datepicker-next {
        right: 0;
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: 50%;
    }
    .ui-datepicker-header a>span {
        display: none;
    }
    .ui-datepicker-title {
        text-align: center;
        line-height: 2rem;
        margin-bottom: 0.25rem;
        font-size: 0.875rem;
        font-weight: 500;
        padding-bottom: 0.25rem;
    }
    .ui-datepicker-week-col {
        color: #78909C;
        font-weight: 400;
        font-size: 0.75rem;
    }
}

.jab-application {
    .head-txt {
        color: #000000;
        font-family: "Poppins Semi Bold";
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.75px;
        line-height: 100%;
    }
    .sub-txt {
        color: #2485e8;
        // font-family: "Poppins Semi Bold";
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.58px;
        text-transform: uppercase;

    }
    .wrk-form {
        width: 100%;
        border-radius: 8px;
        // overflow: hidden;
    }
    .header-wrp {
        padding: 23px 40px;
        // border-radius: 8px 8px 0 0;
        background-color: #f1f1f1;
    }
    .wrapper {
        max-width: 737px;
        width: 100%;
    }
    .form-wrp {
        display: flex;
        justify-content: center;
        padding: 40px 0;
        background-color: #ffffff;
        // border-bottom: 2px dashed ;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            border-bottom: 2px dashed #efefef;
            top: -8px;
            bottom: 0px;
            left: -8px;
            right: -8px;
          }
    }
    .btn-lbl {
        color: #000000;
        // font-family: "Poppins Medium";
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .date-input {
        padding: 0 !important;
        border: 0 !important;
    }
    .btn-job {
        min-width: 347px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid #b4b4b4;
        padding: 16px;
       
    }
    .btn-wrp:nth-child(odd) {
        margin-right: 20px;
    }
    .btn-wrp:nth-child(even) {
        margin-left: 20px;
    }
    .date-input::placeholder,
    .btn-job::placeholder {
        color: #b4b4b4;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
    }
    .btn-container {
        margin-bottom: 40px;
    }
}
