@mixin box-sizing($box-sizing: border-box) {
    -webkit-box-sizing: $box-sizing;
    -moz-box-sizing   : $box-sizing;
    box-sizing        : $box-sizing;
}

@mixin center-vertically($position: absolute) {
    position         : $position;
    top              : 25%;
    -webkit-transform: translateY(-25%);
    -ms-transform    : translateY(-25%);
    transform        : translateY(-25%);
}

@mixin text-size-adjust {
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust   : 100%;
    -ms-text-size-adjust    : 100%;
}

@mixin transform($transform) {
    -ms-transform    : $transform;
    -webkit-transform: $transform;
    transform        : $transform;
}

@mixin transition($parameters: all 0.5s ease) {
    -webkit-transition: $parameters;
    -moz-transition   : $parameters;
    -ms-transition    : $parameters;
    -o-transition     : $parameters;
    transition        : $parameters;
}

@mixin user-select {
    -webkit-user-select: none;
    -moz-user-select   : none;
    user-select        : none;
}


.body {
    overflow: hidden;
}

.gs-bookmark {
    fill: $white;
}

.header-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    background: transparent;
    transition: all ease .5s;

    &.sticky, &.header-white {
        background: $white;
        transition: all ease .5s;
        box-shadow: 0 1px 3px #d0d0d0;

        .nav-link {
            color: #758698;
            transition: all ease .5s;

            &:hover, &:focus, &.active {
                color: #2485e8;
                transition: all ease .5s;
            }

        }

        .nav-btn {
            background: #00aeef;
            color: $white;
    
            @include bp('mobile') {
                color: #505050 !important;
            }
        }

        .gs-bookmark {
            fill: rgb(116, 134, 153);
        }

        .gs-bookmark-stroke {
            // stroke: rgb(116, 134, 153);
            stroke: $white;
        }
        
        // Mobile Hamberg
        .nav-toggle .nav-toggle-bar, 
        .nav-toggle .nav-toggle-bar::after, 
        .nav-toggle .nav-toggle-bar::before{
            background: #40c0f8;
        }
    }
}

// White header
.header-white {
    box-shadow: none !important;
    .nav-link {
        color: #758698 !important;
        &:hover, &:focus, &.active {
            color: #2485e8 !important;
        }
    }
    &.sticky {
        box-shadow: 0 1px 3px #d0d0d0 !important;
    }
}


// Header styling

.nav-header {
    display: flex;
    align-items: center;
    padding: 1rem 0rem;
    @include bp("md") {
        padding: 1.2rem 0;
    }
}

.navbar-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    
    @include bp("md") {
        margin-left: 2rem;
        justify-content: space-between;
    }
    @include bp("lg") {
        margin-left: 3rem;
    }
}

.navbar-list {
    display: flex;
    @include bp('mobile') {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        min-width: 200px;
        background: $white;
        padding-top: 4.5rem;
        flex-direction: column;
        padding-left: .5rem;
        transform: translateX(100%);
        @include transition(all 0.5s ease);
        z-index: 1;
        &__item {
            margin-bottom: 1.2rem;
        }

        .nav-link {
            color: #505050 !important;
            font-size: 14px !important;
            line-height: 24px !important;
            font-weight: 500 !important;
        }
        &.expanded {
            transform: translateX(0%);
            @include transition(all 0.5s ease);
        }
    }
}

.navbar-right-list {
    display: flex;
    align-items: center;
}

.nav-btn {
    @include bp('mobile') {
        background: transparent !important;
        padding: 0 !important;
        font-weight: $fnt-reg !important;
        color: $white !important;
        margin-right: 2.5rem;
    }
    @include bp("md") {
        padding: .5rem 2rem !important;
    }
    // @include bp("lg") {
    //     padding: .55rem 2.2rem !important;
    // }
}

.nav-logo {
    &__img {
        @include bp('mobile') {
            height: 35px;
            width: 35px;
        }
    }
}

// Nav-list

.nav-link {
    @extend .fnt-sm;
    font-weight: $fnt-bold;
    color: $white;
    padding: 0 !important;
    margin: 0 .8rem;
    @include bp("lg") {
        margin: 0 1rem;
    }
}

.nav-favourite {
    position: relative;
}

.gs-notify-dot {
    position: absolute;
    top: 0;
    right: -3px;
    height: 8px;
    width: 8px;
    background: #de3940;
    border-radius: 50%;

    &--rght {
        right: 22px;
    }
}


// Mobile Hamberg menu

.nav-toggle {
    display: block;
    @include bp("md") {
        display: none;
    }
}

@include bp ("mobile") {
    .nav-toggle {
        @include user-select;
        cursor     : pointer;
        height     : auto;
        right      : 15px;
        position   : absolute;
        width      : 35px;
        z-index    : 2;
        display    : table;

        &:hover {
            cursor : pointer;
            opacity: 1;
        }

        .nav-toggle-bar,
        .nav-toggle-bar::after,
        .nav-toggle-bar::before {
            @include center-vertically;
            @include transition(all 0.5s ease);
            background: white;
            content   : '';
            height    : 3px;
            width     : 70%;
            right     : 0;
            border-radius: 3px;
        }

        .nav-toggle-bar {
            margin-top: 0;
        }

        .nav-toggle-bar::after {
            margin-top: 9px;
            // width: 50%;
        }

        .nav-toggle-bar::before {
            margin-top: -9px;
            // width: 100%;
        }

        &.expanded .nav-toggle-bar {
            background: transparent !important;
        }

        &.expanded .nav-toggle-bar::after,
        &.expanded .nav-toggle-bar::before {
            background: #40c0f8;
            margin-top: 0;
        }

        &.expanded .nav-toggle-bar::after {
            @include transform(rotate(45deg));
        }

        &.expanded .nav-toggle-bar::before {
            @include transform(rotate(-45deg));
        }
    }
}

.hamb-overlay {
    display: none;
    @include bp ("mobile") {
        background: rgba(0, 0, 0, .4);
        position: absolute;
        height: 100vh;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        
        &.show {
            display: block;
            opacity: 1;
        }
    }
}