
// Upload resume css

.gs-uploader-container {
    color: #212121;
    padding-top: 2rem;
    padding-bottom: 2rem;
    span {
        font-weight: $fnt-reg;
    }
}

.gs-uploader-heading {
    font-size: 24px;
    line-height: 34px;
    font-weight: $fnt-semi;
}

// Steps css 

#msform .steps-form {
    position: relative;
    &:not(:first-of-type) {
        display: none;
    }
}

.btn-blue {
    outline: none;
    cursor: pointer;
    padding: 9px 16px;
    border-radius: 5px;
    background-color: #00adf2;
    color: #ffffff !important;
    // font-family: "Poppins Medium";
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    outline: none;

    &:focus {
        outline: none;
    }
}

.gs-panel {
    &__header {
        background: #f1f1f1;
        border-radius: 8px 8px 0 0;
        padding: 1rem 1.5rem;
        @include bp("md") {
            padding: 1.4rem 2rem;
        }
    }

    &__subheading {
        @extend .fnt-sm;
        color: #2485e8;
        text-transform: uppercase;
        font-weight: $fnt-semi;
        margin-bottom: 1rem;
    }
    &__head-sm {
        margin-bottom: 38px;
        color: #2485e8;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.5px;
    }

    // Dashboard
    &--inner {
        // box-shadow: 0 2px 9px 3px rgba(0,0,0,.04);
        border-radius: 8px;
        background: $white;
        padding: {
            top: 2rem;
            bottom: 1rem;
            left: 2rem;
            right: 2rem;
        };
    }

}

.gs-steps-categ {
    position: relative;
    margin-bottom: 2.2rem;
    padding-bottom: .8rem;
    
    @include bp("md") {
        margin-bottom: 2.4rem;
    }

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        &::after {
            display: none;
        }
    }
    &::after {
        content: "";
        position: absolute;
        left: -1.4rem;
        right: 0;
        bottom: 0;
        border-bottom: 2px dashed #efefef;
        width: calc(100% + 2.8rem);
        
        @include bp("md") {
            left: -2rem;
            width: calc(100% + 4rem);
        }
        
        @include bp("lg") {
            left: -2.5rem;
            width: calc(100% + 5rem);
        }
        
        @include bp("xl") {
            left: -5rem;
            width: calc(100% + 10rem);
        }

        @media (min-width: 1300px) {
            width: calc(100% + 12rem);
            left: -6rem;
        }
    }

    //Dashboard 

    &--hr {
        &::after {
            width: calc(100% + 4rem) !important;
            left: -2rem !important;
        }
    }

    &__heading {
        @extend .fnt-lg;
        letter-spacing: 1px;
        text-transform: uppercase;
        @extend .fnt-semi;
    }
}