.gs-multiselect-container-accordian {
    .btn--selection {
        border: 1px solid #cccccc;
        width: 100%;
        margin-top: 4px;
        min-height: 40px;
    }
    .multiselect-selected-text {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        width: 180px;
    }
    .dropdown-menu.show {
        position: relative !important;
        top: 0 !important;
        transform: translate3d(0px, 0, 0px) !important;
        width: 100%;
    }
    .multiselect-container {
        max-height: 100% !important;
        padding: {
            left: 1rem;
            right: 1rem;
        }
        border-radius: 0;
    }
}

.comments{
    align-self: center;
}