// flat-ficker over-ride

.flatpickr-months {
    padding: 1.2rem 0;

    // prev and next arrow
    .flatpickr-next-month, .flatpickr-prev-month{
        height: 30px;
        width: 30px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        border-radius: 100%;
        padding: 0;
        top: 20px;
        @extend .flex-center;
        transition: all .3s linear 0s;
            svg{
                margin: 0 auto;
            }
            &:hover, &:focus, &:active{
                transition: all .3s linear 0s;
                background: #2485e8;
                svg{
                    fill: $white;
                }
            }
    }
    .flatpickr-next-month{
        right: 8px !important;
    }
    .flatpickr-prev-month{
        left: 8px !important;
    }
}

 //weekend info

 .flatpickr-current-month, .flatpickr-current-month input.cur-year{
     text-transform: uppercase;
     @extend .fnt-md;
     font-family: 'Poppins', sans-serif !important; 
     font-weight: 600;
 }
 span.flatpickr-weekday, .flatpickr-current-month span.cur-month{
    font-weight: lighter!important;
}

// falt-ficker day

.flatpickr-day:hover, .flatpickr-day:focus, .flatpickr-day.today{
    background-color: #00aeef;
    border: 1px solid transparent;
    color: $white;
}

.flatpickr-day.selected{
    background: #2485e8;
    border: transparent;
}

.input-group {
    position: relative;
}

.input-field~.input-icon {
    position: absolute;
    right: 1rem;
    // top: 50%;
    top: 25px;
    transform: translateY(-50%);
    cursor: pointer;
}