.dashboard-ov {
    .date-txt {
        color: #a0a1a2;
        // font-family: "Poppins Semi Bold";
        font-size: 12px;
        font-weight: 600;
    }
    &__head {
        display: flex;
        justify-content: space-between;
        padding-bottom: 22px;
        border-bottom: 1px solid #eff0f1;
    }
    .camp-drpdown {
        width: 240px !important;
        height: 37px !important;
        .menu {
            border: 0 !important;
        }
    }
 
    .card-overvies {
        margin-top: 38px;

        &__wrap {
            margin-top: 12px;
            display: flex;
            flex-wrap: wrap;
       

        }
        &__card {
            display: flex;
            align-items: center;
            padding: 16px 26px;
            min-width: 194px;
            box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
            border-radius: 8px;
            background-color: #ffffff;
            margin-right: 30px;
            margin-bottom: 8px;
            &:last-child {
                margin-right: 0;
            }
        }
        &__body {
            text-align: center;
        }
        &__text {
            line-height: 1;
            color: #464646;
            font-size: 30px;
            font-weight: 600;
        }

        &__subTxt {
            color: #ababab;
            font-size: 12px;
            font-weight: 500;
        }
    }
    .dashboard-container {
        margin-top: 60px;
        display: flex;
        &__head {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__wrap {
            width: 530px;
            &:first-child {
                margin-right: 16px;
            }
            &:last-child {
                margin-left: 16px;
            }
        }
        .time-laps {
            min-width: 55px;
        }
        &__info {
            margin-top: 12px;
            
            height: 400px;
            box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
            border-radius: 8px;
            background-color: #ffffff;
            .list-item {
                padding: 16px 27px 16px 24px;
                border-bottom: 1px solid #eff0f1;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .h6 {
                color: #131313;
                font-size: 13px;
                font-weight: 500;
            }
            p {
                color: #929292;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
            }
            .time-ago {
                color: #5c5c5c;
                font-size: 10px;
                font-weight: 500;
            }
        }

        &__schedule {
            padding: 20px 28px 0;

            .h4 {
                color: #373737;
                font-size: 13px;
                font-weight: 600;
            }
        }
    }
    .dash-schedule {
        .line::before{
            height: 102% !important;
        }
        .time-h {
            color: #4d4d4d;
            font-size: 13px;
            font-weight: 500;
            text-transform: uppercase;
        }
        h6 {
            color: #4b4c4c;
            font-size: 10px;
            font-weight: 400;
            letter-spacing: 0.42px;
        }
        .txt-12 {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
        }
    }
    .dash-schedule {
        padding-left: 20px !important;
    }
    .profile-info {
        display: flex;
        // align-items: center;
        .profile-img {
            margin-top: 6px;
            width: 37px !important;
            height: 37px !important;
            border-radius: 50%;
            // margin-right: 10px;
        }
        img {
            width: 37px;
            height: 37px;
        }
        .w-197 {
            width: 197px;
            margin-right: 16px !important;
        }
        .interview-item {
            margin-right: 32px;
            &:last-child {
                margin-right: 0px !important;
            }
        }
    }
    .profile-details {
        margin-left: 10px;
        .h5 {
            color: #131313;
            font-size: 13px;
            font-weight: 600;
            line-height: 24px;
        }
        span {
            @extend .h5;
            font-weight: 400;
        }
    }
}
.gry-lbl {
    color: #99999a;;
    font-size: 12px;
    font-weight: 600;
}
.page-h4 {
    color: #393939;
    font-size: 28px;
    font-weight: 600;
}
.badg-wrp {
    margin-left: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #00acf5;
    color: #ffffff;
    font-size: 9px;
    font-weight: 600;

}
.seeAll {
    color: #00acf5;
    font-size: 12px;
    font-weight: 600;
    i {
        font-size: 12px !important;
    }
}

.fnt-14-b {
    color: #2d2d2d;
    font-size: 14px;
    font-weight: 600;
}
.pt-60 {
    padding-top: 60px;
}

.insights-dash {
    &__wrp {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        @media screen and (max-width-1335px){
            flex-wrap: wrap;
        }
    }
    &__card {
        padding: 16px 32px;
        width: 342px;
        box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        background-color: #ffffff;
        margin-right: 32px;
        margin-bottom: 8px;
        &:last-child {
            margin-right: 0;
        }
    }
    &__h6 {
        margin-bottom: 6px;
        color: #7d7d7d;
        font-size: 14px;
        font-weight: 500;
    }
    .h3 {
        color: #494949;
        font-size: 30px;
        font-weight: 600;
        line-height: 43px;
    }

    &__body {
        display: flex;
        justify-content: space-between;
    }
    &__img {
        width: 150px;
        height: 50px;
        align-self: flex-end;
        & img {
            width: 100%;
            height: 100%;
        }
    }
    .range {
        font-size: 14px;
        font-weight: 500;
        &--red {
            color: #e84a50;
        }
        &--green {
            color: #50b737;
        }
    }
}

.drop-sm {
   min-width: 112px !important;
}
.w-50{
    width: 50% !important;
}
.load-generator {
    &__container {
        padding: 32px 32px 32px 24px;
        box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        background-color: #ffffff;
    }

    &__head {
        display: flex;
        justify-content: space-between;
    }

    .indicator {
        position: relative;
        color: #4a4a4a;
        // font-family: "Proxima Nova Rg";  
        font-size: 12px;
        font-weight: 400;
        margin-right: 28px;
        &::before {
            position: absolute;
            content: '';
            width: 8px;
            height: 8px;
            background-color: #ffffff;
            border-radius: 50%;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
        }
        &--blue {
            &::before {
                border: 2px solid #00aeef;
                
            }
        }
        &--red {
            &::before {
                border: 2px solid #e8430d;
           
            }
        }
        &--green {
            &::before {
                border: 2px solid #10c760;
           
            }
        }
    }
}