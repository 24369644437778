.login{
    background: linear-gradient(180deg, #062038 0%, #052b4f 100%);
    text-align: center;
    padding: 10%;
    overflow-x: hidden;

    .title{
        font-family: $font-poppins;
        color: #ffffff;
            &--sub{
                opacity: 0.3;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin-top: 0;
            }
            &--grid-title{
                font-size: 12px;
                margin-bottom: 15px;
                font-weight: 500;
                margin-top: 15px;
            }
            &--product-title{
                font-size: 9px;
                font-weight: 400;
            }
            &--desktop-title{            
                font-size: 20px;
                font-weight: 400;
                line-height: 50px;   
            }
    }
    
    .mobile-title{
        font-family: $font-poppins;
        color: #ffffff;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.1px;
        margin-top: 20px;
    }
    
    .login-btn{
        background: #00adee;
        width: 100%;
        color: white;
        font-family: $font-poppins;
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
    }
    
    .btn-forgot{
        font-family: $font-poppins;
        text-align: left;
        margin-left: 0; 
        color: #00adee;
        font-size: 14px;
        font-weight: 400;
        float: left;
        background: transparent;
        padding-left: 0px !important;
    
    }
    

    .forgot{
        padding: 15px 0;
        border-bottom: 1px dashed #fff;
        justify-content: space-between;
    }
    
    .product-image{
        width: 25px;
        height: 25px;
        align-self: center;
    }
    
    .product{
        border: 1px solid rgba(255,255,255,0.2);
        border-radius: 5px;
        padding: 8px;
    }
    
    .product-column{
        width: 33.3% !important;
        padding: 0;
        // margin: 10px;
    }
    
    .available-on{
        color: #8295A7;
        font-family: $font-poppins;
        font-size: 9px;
        font-weight: 500;
    }
    .download-icon{
        vertical-align: center;
        margin-left: 10px;
        align-self: center;
        &--apple{
            width: 16px;
            height: 18px;
        }
        &--android{
            width: 15px;
            height: 20px;
        }
    }
    
    .floating-label-group {
        position: relative;
        margin-top: 15px;
        margin-bottom: 25px;
        width: 100%;
        
    
    
        .floating-label {
            font-size: 13px;
            color: #8ba0af;
            position: absolute;
            pointer-events: none;
            top: 9px;
            left: 0;
            padding-bottom: 5px;
            transition: all 0.1s ease;
            font-weight: 400;
        }
    
    
        input:focus ~ .floating-label,
        input:not(:focus):valid ~ .floating-label {
            top: -15px;
            bottom: 0px;
            left: 0px;
            font-size: 11px;
            opacity: 1;
            color: #8ba0af;
            text-align: left;
           
        }
    }
    
    
    .text{
        font-size: 13px;
        color: #8ba0af !important;
        font-weight: 400;
        font-family: $font-poppins;
        
    }
    
    .dropdown-label{
        color: #8ba0af;
        font-size: 13px;
        top: 9px;
        left: 0;
        padding-bottom: 5px;
        width: 80%;
        text-align: left;
    }
    

    .dropdown:after {
        font-family: "Glyphicons Halflings";
        content: "\e114";
        float: right;
        margin-left: 15px;
      }
      /* Icon when the collapsible content is hidden */
      .dropdown.collapsed:after {
        content: "\e080";
         
      }
    
    .top{
        margin-top: 50px;
    }
    
    
    .form-control{
        background: rgba(255,255,255,0);
        padding-bottom: 5px;
        width: 100%;
        border: none !important;
        color: #fff;
        font-family: $font-poppins;
    }
    
    .ui.selection.dropdown {
        background: transparent !important;
        border-bottom: 1px solid #435B70 !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        border-radius: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    
    }
    
    .ui.fluid.dropdown {
        height: auto !important;
        padding-left: !important;
        padding-right: 0 !important;
        margin-bottom: 15px !important;
    
    }
    
    .dropdown-icon{
        color: #606265 !important;
    }
    
    .form-group{
        border-bottom: 1px solid #435B70;
    }
    
    
    .form-control:focus{
        border-bottom: 1px solid #0b6488;
        background: rgba(255,255,255,0);
        outline: none !important;
    }
    .left-login-section{
        background: #161920;
        padding: 3% 5%;
    }
    .login-right-section{
        background: #193663;
        padding: 5% 0;
    }
    .banner{
        position: relative;
    }
    .login-right-vector{
        width: 100%;
        height: 75vh;
        // position: absolute;
        margin-top: -100px;
        left: 0;
    }
    .desktop-logo{
        width: 100px;
        height: 100px;
        top: 100;
    }
    .gsf-logo{
        margin-top: 80px;   
        width: 127px;
        height: 25px;
    
    }
    .global-news{
        color: #00aeef !important;
    }
    .check-out{
        font-size: 12px;
        color: #515865;
        margin-top: 10px;
        font-family: $font-poppins;
    }
    .footer-link{
        color: #ffffff !important;
        font-weight: 300px;
        margin: 10px 0;
        padding: 0 10px;
        font-size: 12px;
        opacity: 0.6;
        font-family: $font-poppins;
    }
    .footer-links{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .border-lr{
        border-left: 1px solid #3a4b7a;
        border-right: 1px solid #3a4b7a;
    
    }
    
    .copy-right{
        font-size: 10px;
        color: #ffffff;
        opacity: 0.4;
        font-family: $font-poppins;
        font-weight: 400;
    }
    
    .checkbox-label{
        font-size: 12px;
        color: #fff;
        font-family: $font-poppins;
        font-weight: 400;
        margin-left: 5px;
    }
    
    .form-check{
        float: left;
    }
    .product-container{
        padding: 0 15%;
        align-self: center;
        flex-direction: row !important;
    }

    .product-section{
        padding: 0 15%;
    }
    

    
    @media only screen and (max-width: 768px) {
    
    .product-container{
        flex-direction: row !important;
        padding: 0 5%;
    }
  
    
    .left-login-section{
        padding: 10%;
        background: linear-gradient(180deg, #062038 0%, #052b4f 100%);
    }
    .login-right-section, .title--desktop-title, .form-check{
        display: none;
    }
    
    .btn-skip{
        text-align: right !important;
        float: right !important;
        color: #697d91 !important;
        margin-right: 0;
        float: right !important;
        align-items: flex-end;    
    }
    
    
    }
    
    
    @media only screen and (min-width: 769px) {
        .login-logo, .mobile-title, .btn-skip, .title--sub{
            display: none;
        }
       
        .title{
            font-family: $font-poppins;
            color: #ffffff;
                &--desktop-title{             
                    text-align: left;
                }
                
        }
    
        .top{
            margin-top: 20px;
        } 
    }
    
}






