
.job-container {
   padding: {
       top: 3rem;
       bottom: 3rem;
   };
   @include bp("md") {
       padding: {
           top: 5rem;
           bottom: 5rem;
       };
   }
}


// JOb-listing filters

.job-listing-filters {
    display: flex;
    @include bp('mobile') {
        flex-direction: column;
    }
    @include bp("md") {
        justify-content: flex-end;
    }
}

// Job-update

.job-update {
    background: url("../img/job-listing/register-banner.png") no-repeat;
    background-size: cover;
    height: 240px;
    color: $white;
    display: flex;
    align-items: center;
    position: relative;
    @include bp("md") {
        height: 350px;
    }
}

.table-desktop {
    display: none;
    @include bp("md") {
        display: block;
    }
    .job-listing-tr {
        cursor: pointer;
    }
}

.table-mobile{
    display: block;
    @include bp("md") {
        display: none;
    }
}

// Only mobile version

.gs-job-panel {
    @include bp('mobile') {
        background-color: rgba(140, 140, 140, 0.09);
        border-radius: $brder-radius4;
        padding: .7rem .8rem;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: .8rem;
        display: block;
        color: $black;
        
        &__head, .gs-job-notify-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__body {
            margin-top: .6rem;
            color: #0a0a0a;
        }

        .gs-job-notify-info{
            border-bottom: 1px solid #e5e5e5;
            padding-bottom: .6rem;
            margin-bottom: .6rem;
        }

        .mb-favourite {
            height: 18px;
            width: 15px;
        }
    }
}

.gs-table-sticky {
    position: sticky;
    top: 60px;
    z-index: 10;
}