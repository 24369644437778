/** buttons **/

.btn {
    outline: none;
    padding: .6rem 2rem;
    border-radius: 5px;
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
    @extend .fnt-sm;
    font-weight: $fnt-semi;
    transition: all .6s ease;

    &:focus {
        outline: none;
    }

    @include bp("md") {
        padding: .65rem 3.5rem;
    }

    &--primary {
        
        background: $white;
        color:  #2f96cc;

        &:hover {
            color: #2f96cc;
        }
    }
    &--lg {
        padding: {
            left: 2rem;
            right: 2rem;
        }
    }

    &--info {
        border: none;
        background: #00aeef;
        color: $white;
        min-width: 150px;
        text-align: center;

        &:hover, &:focus {
            color: $white;
        }
        @include bp("md") {
            min-width: 190px;   
        }
        padding : {
            top: .6rem;
            bottom: .6rem;
            left: 0;
            right: 0;
        }
    }

    &--add {
        border: 1px solid #00adf2;
        color: #00adf2;
        @extend .fnt-sm;
        font-weight: 500;
        transition: all .6s ease;
        padding: {
            top: .5rem;
            bottom: .5rem;
            left: 1rem;
            right: 1rem;
        };
        @include bp("md") {
            padding : {
                left: 1.2rem;
                right: 1.2rem;
            }
        }
        &:hover, &:focus {
            transition: all .6s ease;
            color: $white;
            background: #00adf2;
        }
    }

    &.disabled {
        background: #cdcdcd;
        pointer-events: none;
    }

    // Dashboard
    &--primary-d {
        @extend .fnt-sm;
        font-weight: $fnt-semi;
        border: 1px solid #00adf2;
        color: #00adf2;
        padding: .4rem 1.2rem !important;
        background: transparent;
        &.filled {
            background: #00adf2;
            color: $white;
        }

        &:hover, &:focus {
            outline: none;
            background: #00adf2;
            color: $white;
        }
    }
    &--info-d {
        border: none;
        background: #00aeef;
        color: $white;
        text-align: center;
        padding: .4rem 2rem !important;

        &:hover, &:focus {
            color: #00aeef;
            background: $white !important;
            border: 1px solid #00adf2;


        }
    }

    &--default {
        border: 1px solid rgba(100,100,100,.6)!important;
        text-transform: capitalize;
        color: #4f4f4f !important;
        font-weight: $fnt-medium;
    }
}

.btn-mobile {
    @include bp('mobile') {
        width: 100%;
        text-align: center;
        margin-top: 0.8rem;
    }
}