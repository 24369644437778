.job-banner-heaading {
    font-size: 26px;
    line-height: 36px;
    font-weight: $fnt-bold;

    @include bp("md") {
        font-size: 40px;
        line-height: 50px;
    }
}
.gs-input-search__icon {
    cursor: pointer;
}
.form-input:focus {
    background: none !important;
}
input:not([value]) ~ .remove.icon
{
   opacity: 0.0;
}
.delete-icon {
    width: 17px;
    height: 20px;
    cursor: pointer;
}
.form-input::-ms-clear {
    display: none;
}
//firefox

@-moz-document url-prefix() {
    .gs-labeled-drodown {
        padding-bottom: 2px;
    }
  }