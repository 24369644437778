// Table Styles
.table-thread {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    color: $text-primary85;

    // Table header
    .table-header {
        border-radius: 5px 5px 0 0;
        // background-color: #F3F3F3;
        
        tr {
            th {
                position: sticky;
                top: 5.2rem;
                background-color: #F3F3F3;
                @extend .fnt-sm;
                font-weight: $fnt-bold;
                padding: 1.8rem 1.2rem;
                z-index: 10;
            }
        }
    }
    .sticky {
        position: fixed;
        top: 84px;
        // bottom: auto;
        // width: 100%;
      } 
    
    // table body

    .table-body {
        tr {
            border-bottom: 2px solid #f5f5f5 !important;
            position: relative;
            a{
                color: $black;
            }
            &:hover {
                background: #f4f9fe;
                border-radius: $brder-radius4;
                transition: all ease .5s;
                td {
                    &:first-child{
                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }

            td {
                @extend .fnt-md;
                padding: 2.35rem 1.2rem;
                // font-weight: $fnt-reg;
                position: relative;
                &:first-child{
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        height: 100%;
                        width: 4px;
                        background: #2485e8;
                        opacity: 0;
                    }
                }
            }

            .table-cell-cretia {
                display: flex;
                justify-content: space-between;
            }

            .thrd-by {
                span {
                    display: block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 100px;
                }
            }

            .thrd-status {
                .thrdStat {
                    border-radius: 5px;
                    vertical-align: middle;
                    display: inline-block;
                    margin-left: 12px;
                }
            }

            .thrd-date {
                width: calc(100% / 8);
                flex-direction: column !important;
            }
        }
    }
}

.open-number {
    font-size: 12px;
    line-height: 24px;
    font-weight: $fnt-medium;
}