.doc-padding{
    padding: 5% !important;
    margin: 2%;
}
.pdf-doc{
    padding: 17px 10px;
    border-radius: 5px;
}
.doc-date{
    @include fonts(10px, 500, #6c7175, $font-poppins);

}

.doc{
    margin-left: 0;
    margin-right: 0;
    padding: 25px 15px !important; 
}
.pdf-img{
    width: 21px;
    height: 25px;
    margin-top: 10px;
}

.img__upload{
    margin-right: 5px;
}

.align-rgt{
    float: right;
    margin-top: 10px;
}

.doc-bottom {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.file-wrapper {
    margin-right: 20px;
    margin-bottom: 25px;
    width: 246px;
}

.pdf-file-uploder-container {
    min-width:1147px !important; 
    width: 100% !important;
    .dropdown.cv-screning-dropdown__list {
        border-radius: 5px;
        border: 1px solid #00aeef !important;
        height: 36px!important;
        width: 130px;
    }
    .pdf-text-gry {
        color: #d4d4d4;
        font-size: 12px;
        font-weight: 500;
  
    }
    .mr-32 {
        margin-right: 32px;
    }
  
    .file-name {
        color: #353535;
        font-size: 16px;
        font-weight: 500;
    }

    .pdf {
        &__body {
            background-color: #ececec;
        }
        .drp-plachlder {
            color: #000000 !important;
            line-height: 1.3;
    
        }
        &__head {
            width: 100%;
            display: flex;
            justify-content: space-between;

            
        }
        &__btn {
            text-transform: capitalize;
            display: flex;
            align-items: center;
        }
        &__pdf-file {
            img {
                width: 503px;
            }
        }
        &__wrper-file {
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            float: right;
            max-width: 800px;
            width: 100%;
        }
        &__info {
            min-width: 305px;
            width: 100%;
            cursor: initial;
            .blue-circle {
                cursor: pointer;
                position: relative;
                float: right;
                width: 40px;
                height: 40px;
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.11);
                background-color: #00aeef;
                border-radius: 50%;

                &:hover ,&:focus {
                   &~ .pdf-info-wrp {
                        opacity: 1;
                        // display: block;
                        visibility: visible;
                        transition: all ease-in-out .3s;
                    }
                }
            }
            .white-circle {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                border-radius: 50%;
                width: 18px;
                height: 18px;
                background-color: #ffffff;

                & span {
                    font-size: 10px;
                    color: #00aeef;
                    font-weight: 500;
                }
            }
        }
        .share-icon {
            width: 17px;
            height: 18px;
        }
    }

 
}


.pdf__info {
    position: relative;
    .pdf-info-wrp {
        opacity: 0;
        // display: none;
        visibility: hidden;
        position: absolute;
        z-index: 9999;
        right: 0;
        top: 52px;
        width: 223px;
        box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.11);
        background-color: #ffffff;
        border-radius: 5px;
        transition: all ease-in-out .8s;

    }
    .info-head {
        padding: 8px 12px;
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        line-height: 32px;
        border-bottom: 1px solid #eaeaea;
    }

    .min-head {
        color: #000000;
        font-size: 12px;
        font-weight: 500;
        line-height: 26px;
        margin-right: 10px;
    }
    .mim-wrp {
        padding: 8px 12px;
        li {
            margin-top: 4px;
            display: flex;
        }
    }
    .min-value {
        @extend .min-head;
        font-weight: 300 !important;
    }
   
    
}
.pdf-info-wrp::after {
    position: absolute;
    content: '';
    top: -5px;
    right: 14px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #ffffff;
    // box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.11);

    }