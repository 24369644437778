
.our-mission-info {
    @include bp("lg") {
        padding-right: 3rem;
    }
    @include bp("xl") {
        padding-right: 6rem;
    }
}

.gs-block-list {
    padding-top: 1.5rem;
    @include bp("lg") {
        padding-top: 2rem;
    }
    &__item {
        @extend .fnt-md;
        color: $black;
        position: relative;
        margin-bottom: .6rem;
        padding-left: 1rem;
        &::before {
            content: "";
            position: absolute;
            height: 6px;
            width: 6px;
            background: black;
            left: 0;
            border-radius: 50%;
            top: 10px;
        }
    }

    &--lg {
        @include bp('mobile') {
            margin-top: 2.5rem;
        }
        @include bp("md") {
            column-count: 2;
            column-gap: 10px;
        }
        @include bp("lg") {
            column-gap: 30px;
        }
        .gs-block-list__item {
            @extend .fnt-lg;
            color: $white;
            margin-bottom: 1rem;

            @include bp("md") {
                margin-bottom: 1.4rem;
            }
            &::before {
                height: 8px;
                width: 8px;
                background: #3299ff;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
