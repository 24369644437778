
/** footer **/

.footer-social-list {
    display: flex;
}

.social-link {
    height: 33px;
    width: 33px;
    border-radius: 50%;
    background: $footer-circle;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 .5rem;
    i {
        display: inline-block;
        position: relative;
        top: 2px;
        color: #424242;
    }
}

.gs-coprgts {
    font-size: 10px;
    line-height: 20px;
    color: #505d68;
    font-weight: $fnt-reg;
}