
@import "home/banner";
@import "home/our-mission";
@import "home/our-awards";
@import "home/our-principals";
@import "home/testimonials";



.section-heading-info {
    @include bp("xl") {
        padding-right: 3rem;
    }
    @media (min-width: 1300px) {
        padding-right: 5rem;
    }
}


.gs-work-giis {
    background: url("../img/home/work-banner.png") no-repeat;
    background-size: cover;
    height: 270px;
    color: $white;
    display: flex;
    align-items: center;
    @include bp('tablet') {
        background-position-x: 50%;
    }
    @include bp("md") {
        height: 320px;
    }
    @include bp("lg") {
        height: 360px;
    }

    &__des {
        font-size: 14px;
        line-height: 24px;
        font-weight: $fnt-light;
        margin: 0 2rem;
        @include bp("md") {
            font-size: 18px;
            line-height: 28px;
            margin: 0 4rem;
        }
        @include bp("lg") {
            margin: 0 7rem;
        }
    }
}

.max-220 {
    @include bp('mobile') {
        max-width: 220px;
    }
}