@charset "UTF-8";

@font-face {
  font-family: "giis";
  src:url("../fonts/giis/giis.eot");
  src:url("../fonts/giis/giis.eot?#iefix") format("embedded-opentype"),
    url("../fonts/giis/giis.woff") format("woff"),
    url("../fonts/giis/giis.ttf") format("truetype"),
    url("../fonts/giis/giis.svg#giis") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "giis" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "giis" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
  content: "\61";
}
.icon-linkedin:before {
  content: "\62";
}
.icon-instagram:before {
  content: "\63";
}
.icon-facebook:before {
  content: "\64";
}
.icon-right-arrow:before {
  content: "\65";
}
.icon-left-arrow:before {
  content: "\66";
}
.icon-angle-down:before {
  content: "\68";
}
.icon-down-arrow:before {
  content: "\68";
}

.icon-bookmark:before {
  content: "\e900";
  color: #fff;
}