/* *   Common css for all the pages   * */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin                 : 0;
    padding                : 0;
    border                 : 0;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering         : optimizeLegibility;

    /*  font-size: 100%; */
    font         : inherit;
}

html {
    -webkit-box-sizing     : border-box;
    box-sizing             : border-box;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering         : optimizeLegibility;
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing        : border-box;
}

ol,
ul {
    list-style: none;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

input[type=text],
textarea {
    font-family      : $font-family ;
    // letter-spacing: 1px;
}

/* =============================================
		body Fonts
============================================= */

body {
    font-family: 'Poppins', sans-serif; // -webkit-font-smoothing: antialiased;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering         : optimizeLegibility;

    font-weight: $fnt-light;
    
    p {
       @extend .fnt-md;
        // color: $theme-primary;
    }
}


.container {
    @include bp("xl") {
        max-width: 1170px !important;
    }
    @media (min-width: 1300px) {
        max-width: 1280px !important;
    }
}


.margin-tp5 {
    margin-top: 4rem;
    @include bp("md") {
        margin-top: 5.2rem;
    }
}

strong {
   font-weight: $fnt-bold; 
}

.fnt-semi {
    font-weight: $fnt-semi; 
}


// Images

.img-fluid {
    max-width: 100%;
}

// backgrounds

.bg-primary {
    background: #007cf1 !important;
    color: $white;
}

.clr-primary {
    color: #00adf2;
}

.bg-blue {
    background: $lght-blue;
    color: $white;
    outline: none;
    &:focus {
        outline: none;
    }
}

// border-radius

.brder-radius5 {
    border-radius: $img-radius5;
}

.brder-primary {
    border-color: #2f96cc;
}

.fnt-reg {
    font-weight: 400 !important;
}

.fnt-medium {
    font-weight: $fnt-medium;
}

.fnt-semi {
    font-weight: $fnt-semi;
}


// Flex

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-reverse {
    @include bp('mobile') {
        flex-direction: column-reverse;
    }
}

// padding

.pdb-0 {
    @include bp('mobile') {
        padding-bottom: 0 !important;
    }
}

// overalys

.bg-primary-overaly {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $primary-gradient;
}

// Mobile width

.m-width100 {
    @include bp('mobile') {
        width : 100%;
    }
}

//  box-shadows

.shadow-none {
    box-shadow: none !important;
}