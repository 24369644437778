.role-profile {
    .h2 {
        color: #212121;
        font-size: 14px;
        font-weight: 600;
    }
    i {
    
        font-size: 12px;
        color: #434444;
    }
    .clr-gry {
        color: #c9c9c9 !important;
    }
    margin-top: 40px;
    // display: flex;
    justify-content: center;
    &__container {
        padding: 32px 28px ;
        max-width: 715px;
        margin: 0 auto;
        // width: 100%;
        box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        background-color: #ffffff;
    }
    .role-info {
 
        max-width: 715px;
        margin: 0 auto;
        
    }
    .role-permissions {
        padding-top: 48px;
        max-width: 715px;
        margin: 0 auto;
    }
    .preset-value {
        color: #000000;
        font-size: 12px;
        font-weight: 500;

        &--bld {
            font-weight: 600;
        }
    }
    .empty-check {
        padding-left: 16px !important;
        color: #212121;
        font-size: 12px;
        font-weight: 400;
        &--pl-0 {
            padding-left: 0 !important;
        }
        &::before {
            display: none !important;
        }
        &::after {
            display: none;
        }
    }
}


.custom-toggle-check {
    padding-top: 8px;
    padding-bottom: 8px;
    .custom-check {
        &::before {
            width: 38px !important;
            height: 20px !important;
            background-color: #b6b6b6 !important;
        }

        &::after {
            top: 1px !important;
            width: 16px !important;
            height: 17px !important;
        }
    }
    .ui.toggle.checkbox input~.box:after, .ui.toggle.checkbox input~label:after {
        left: .2rem;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    checkbox input:checked~.box:after, .ui.toggle.checkbox input:checked~label:after {
        left: 1.17rem !important;
    }

    .ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
        background-color: #61bc17 !important;
    }
}

.module-list {
    border: 1px solid #eaeaea;
    border-top: none;
    box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    background-color: #ffffff;
    &__header {
        border-radius: 8px 8px 0 0 ;
        display: flex;
        justify-content: space-between;
        padding: 16px 40px 13px 24px ;
        background-color: #f2f2f2;
        .txt-12 {
            color: #2f2d2d;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
        }
    }

    &__list-item {
        cursor: pointer;
        border-bottom: 1px solid #f5f5f5;
        padding: 8px 24px ;

   
    }
}

.roll-dropdown {
    .btn--selection {
        width: 100%;
    }
    .multiselect-selected-text {
        width: 90%;
        color: #b4b4b4;
        font-size: 12px;
        font-weight: 400;
    }
    .multiselect-container {
        padding-left: 6px !important;
        width: 90%;
        & li {
            padding: 6px 12px;
        }
        & li:hover {
            background-color: #e4f7ff;
        }
    }
}