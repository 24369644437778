.gs-input-container {
    cursor: pointer;
}

.gs-placeholder-cnt {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0 12px;
    justify-content: space-between;
}

.gs-dropdownselect {
    display: inline-block;
    position: relative;
    font-size: 12px;
    color: #6c6c6c;
    border-radius: 2px;
    border: solid 1px #ccc;
    background-color: #fff;
    margin-left: 1.2rem;
    min-width: 140px;
    border-radius: 4px;
}

.gs-select{
    max-width: 150px;
}

// select content 

.gs-content-container {
   position: absolute;
//    bottom: -3.2rem;
   top: 120%;
   right: 0;
   min-width: 100%;
   width: 180px;
   scroll-behavior: smooth;
   z-index: 10;
   display: none;
}

.gs-content-inner {
    background: $white;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.11);
    border-radius: $brder-radius4;
    padding: {
        left: .8rem;
        right: .8rem;
        top: .8rem;
    }
}


//Bootstrap selection

.multiselect-selected-text {
    font-size: 12px;
    line-height: 24px;
    text-transform: capitalize;
    color: rgba(33, 33, 33, 0.71);
    font-weight: 400;
}

.btn--selection {
    width: 140px;
    // width: 100%;
    padding: .5rem .8rem;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: $brder-radius4;
    border: 1px solid #b4b4b4;
    min-height: 48px;
    cursor: pointer;
    &:focus, &.active {
        outline: none;
        box-shadow: none;
    }
}

.multiselect-selected-text {
    white-space: nowrap;
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.multiselect-container {
    // transition: 0 !important;
    z-index: 99;
    top: 10px !important;
    border: none;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.11);
    border-radius: $brder-radius4;
    max-height: 175px;
    overflow-y: auto;
    padding: {
        left: .8rem;
        right: .8rem;
        top: .5rem;
        bottom: .5rem;
    }
}

.multiselect-container>li>a>label.checkbox {
    font-size: 12px;
    line-height: 24px;
    color: $black ;
    position: relative;
    padding-left: 1.4rem;
    font-weight: 400;
}

.multiselect-container>li>a>label {
    padding: 0;
}

.multiselect-container input + span:before {
    content: '';
    margin-right: 11px;
    display: inline-block;
    vertical-align: text-top;
    width: 12px;
    height: 12px;
    background: 0 0;
    margin-top: 2px;
    border: 1px solid #00aeef;
    border-radius: 2px;
  }

.multiselect-container input:checked+span:before {
    background: #00aeef;
}

.multiselect-container span {
    position:absolute;
    left: 0;
    top:0px;
}

.multiselect-container input {
    display:none;
}
  
.multiselect-container input:checked + span:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 6px;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
}

.multiselect-container>li>a {
    margin-bottom: 5px;
    display: inline-block;
    &:focus {
        outline: none;
    }
}

.dropdown-toggle {
    position: relative;
    background: transparent;
    &::after {
        position: absolute;
        right: .8rem;
        top: 44%;
        border: solid #00aeef;
        border-width: 0 2px 2px 0 !important;
        display: inline-block;
        padding: 3px;
        transform: translateY(-50%) rotate(45deg);
        transition: all .6s ease;
    }
}

.btn-group.show {
    transition: all .6s ease;
    .dropdown-toggle {
        &::after {
            transform: rotate(-135deg);
        }
    }   
}


//Semantic droprdown

.ui.dropdown:not(.button)>.default.text {
    color: #b4b4b4;
}

.dropdown-icon {
    position: absolute;
    top: 50%;
    right: .8rem;
    transform: translateY(-50%);
    display: inline-flex;
    font-size: 11px;
    color: #00adf2;
}
.ui.selection.active.dropdown{
    .dropdown-icon {
        transform: translateY(-50%) rotate(-180deg);
    }
}


.gs-labeled-drodown {
    display: flex;
    border: 1px solid #b4b4b4;
    border-radius: $brder-radius4;

    .ui.selection.dropdown {
        min-width: auto !important;
        width: 5rem !important;
        border: none;
        position: relative;
        padding: .8rem !important;
        &::after {
            content: "";
            position: absolute;
            right: 0;
            height: 70%;
            width: 1px;
            background: $input-plchlder;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .ui.selection.active.dropdown {
        box-shadow: none;
    }

    .form-input {
        min-height: 47px !important;
    }

    .dropdown-icon {
        font-size: 10px;
        // right: 1.2rem !important;
        top: 50% !important;
    }

    .ui.form input[type=text], .form-input {
        border: none !important;
    }

    .text, .ui.selection.dropdown .menu> .item {
        @extend .fnt-xs;
        font-weight: $fnt-reg;
    }

    .ui.dropdown:not(.button)>.default.text {
        color: #212121;
    }
    
    .ui.selection.dropdown .menu>.item {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}
