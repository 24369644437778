//Apply Filter
.filter-section {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: #09090933;
    background-color: rgba(9, 9, 9, 0.2);;
    
    bottom: 0;
    right: 0;
    z-index: 11;
    transition: all ease-in-out .5s;
    transform-origin: top right;
  
}
.filter-container {
    transform-origin: top right;

    position: fixed;
    top: 71px;
    right: -100%;
    max-width: 260px;
    height: calc(100vh - 71px);
    padding: 30px 15px;
    box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    overflow-y: auto;
    transition: 0.6s;
    // transition: 0.7s ease-in-out;

    @media (min-width: 992px) {
        max-width: 332px;
        padding: 50px 25px;
    }

    &__hide {
        float: right;
        transform: translateY(-25px);
        cursor: pointer;

        @media (min-width: 992px) {
            transform: translateY(-35px);
        }
    }

    &.open {
        right: 0;
    }
}

.filter-select {
    margin-bottom: 15px;

    &__title {
        color: #000000;
        font-size: 12px;
        line-height: 22px;
        margin-bottom: 10px;
        font-weight: 500;
    }

    &__head {
        color: #b4b4b4;
        font-size: 12px;
        font-weight: 400;
        padding: 10px 20px 10px 10px;
        cursor: pointer;
        border-radius: 4px;
        background-color: inherit;
        border: 1px solid #cccccc;  
        margin-bottom: 2px;
        position: relative;

        .icon-arrow {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%) rotate(180deg);
            z-index: 1;
            pointer-events: none;
            transition: 0.3s ease-in-out;
        }

        &.active {
            .icon-arrow {
                transform: translateY(-50%) rotate(0deg);
            }
        }
    }

    &__body {
        padding: 15px;
        box-shadow: 0 3px 14px rgba(0, 0, 0, 0.11);
        border-radius: 4px;
        background-color: #ffffff;
        width: 100%;
        height: 160px;
        overflow-y: auto;
        display: none;
        position: relative;

        .checkbox {
            display: block;
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        
    }
}

.label {
    color: #000000;
    font-size: 12px !important;
    line-height: 22px;
    font-weight: 400;

    &--radio {
        padding: 0 0 0 20px !important;
        line-height: 15px;
    }

    span {
        font-size: 10px !important;
    }
}