// Job Dashboard Hedaer
.header-dashboard {
    box-shadow: 0 6px 12px -1px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    padding: 12px;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    min-height: 71px;
    top: 0;
    z-index: 999;

    @media (min-width: 768px) {
        padding: 12px 20px;
    }

    @media (min-width: 992px) {
        padding: 12px 13px;
    }

    @media (min-width: 1200px) {
        padding: 12px 40px;
    }

}

.company-logo {
    display: inline-block;

    img {
        @media (max-width: 767px) {
            width: 35px;
            height: 35px;
        }

        width: 47px;
        height: 47px;
        object-fit: contain;
    }
}

.nav-account {
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;

    @media (min-width: 768px) {
        width: 80%;
        
    }

    &__search {
        float: left;
        margin-left: 10px;

        @media (min-width: 768px) {
            margin-left: 25px;
            
        }

        @media (min-width: 992px) {
            margin-left: 30px;
            
        }
      
      

        input {
            border: 0;
            outline: 0;
            background: none;
            width: 100px;
            display: none;
            opacity: 0;
        }

        button {
            border: 0;
            outline: 0;
            background: none;

        }
    }

    &__notification {
        float: left;
        position: relative;
        margin-left: 10px;

        @media (min-width: 768px) {
            margin-left: 25px;
            
        }

        @media (min-width: 992px) {
            margin-left: 30px;
            
        }
      

        .notification-count {
            position: absolute;
            top: 1px;
            right: 0;
            width: 8px;
            height: 8px;
            background-color: #e51b24;
            border-radius: 50%;
            font-size: 10px;
            line-height: 10px;
            padding: 1px 0;
            text-align: center;
            z-index: 1;
        }
    }

    &__profile {
        float: left;
        margin-left: 10px;

        @media (min-width: 768px) {
            margin-left: 25px;
            
        }

        @media (min-width: 992px) {
            margin-left: 30px;
            
        }
      

        .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;

            @media (min-width: 768px) {
                width: 40px;
                height: 40px;
                
            }
        }

        .name {
            color: #3e3d34;
            font-size: 12px;
            font-weight: 500;
            display: none;

            @media (min-width: 768px) {
                margin-left: 8px;
                display: inline-block;
            }
        }
    }
}

.pt-70 {
    @media (max-width: 767px) {
        padding-top: 59px;
    }
    padding-top: 71px;
}

.gray-fb {
    background-color: #f9fafb;
}

.sec-pad {
    padding: 15px 0;
    
    @media (min-width: 992px) {
        padding: 35px 0;
       
    }

    @media (min-width: 1200px) {
        padding: 50px 0;
    }
}