
.dropzone.dz-clickable * {
    cursor: pointer;
}

.gs-upload {
    @include bp("md") {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;

        &--xs {
            min-height: 196px;
        }
        &--sm {
            min-height: 236px;
        }
        // Large uploader
        &--lg {
            min-height: 280px;
        }
    }
}

.file-upload-wrp {
    // margin-top: 50px;
    display: flex;
    position: relative;
    border: none !important;
    border: 2px dashed rgba(117, 117, 117, .5) !important;

    @include bp("mobile") {
        border-width: 1px !important;
    }
    .file-upload-info {
        margin: 60px auto;
    }

    &--whte {
        background: rgba($color: $white, $alpha: .5);
        border-color: rgba($color: #969696, $alpha: .5);
    }

    .dz-message  {
        width: 100%;
        // @include bp("mobile") {
        //     display: flex;
        //     flex-direction: column;
        // }
        // @media screen and (max-width:767px) {
        //     margin: 0 !important;
        //     flex-direction: row;
        // }
    }
      
    .upload-txt {
        color: #6d7278;
        font-weight: 400;
        font-size: 10px;
        
        @media screen and (min-width:768px) {
        // padding-top: 20px;
        line-height: 38px;
        display: block;
        font-size: 12px;
    }
    
}

// Message wrapper
.msg-wrp {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // width: 100%;
    // @media screen and (min-width:768px) {
    //     flex-direction: column;
    // }
    
    @include bp("mobile") {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    img {
        display: none;
        margin: 0 auto;
        @media screen and (min-width:768px) {
            display: block;
            
    }
}

    .upload-browse {
        width: 90px;
        cursor: pointer;
        color: #ffffff;
        display: inline-table;
        // font-family: "Poppins Semi Bold";
        font-size: 12px;
        font-weight: 600;
        border-radius: 3px;
        background-color: #0bafed;
        padding: 6px 8px;
        @media screen and (min-width:768px) {
            background: transparent;
            font-size: 14px;
            // display: block;
            color: #00aeef;
            font-size: 12px; 
        }
    }



}
.table-striped {
    align-self: start;
    width: auto !important;
    height: auto !important;
    margin-bottom: 0 !important;
}

/* resume upload*/
.dz-size {
    display: none   ;
}
 .dz-preview {
    width: 96px !important;
    margin: 0 !important;
    margin-bottom: 12px !important;
    margin-right: 50px !important;
    padding-top: 12px;
    background-color: #f1f1f1 !important;
}

 .dz-image {
    margin: auto !important;
    border-radius: 0 !important;
    width: 78px !important;
    height: 108px !important;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
 .dz-details {
    position: relative !important;
    padding: 0 !important;
    opacity: 1 !important;
}
.size {
    display: none;
}
.dz-filename span,.name {
    color: #353535;
    font-family: Poppins;
    font-size: 8px;
    font-weight: 400;
    padding: 6px 0;
    text-align: center;

    &:hover {
        background-color: transparent !important;
        outline: none !important;
        border: none !important;
    }
}


//circle progress 

$circle-size: 27px;
$circle-background: #81818199;
$circle-color: #ffffff;
$inset-size: 23px;
$inset-color: #81818199;
$transition-length: 1s;
.progress-resume {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #81818199;
}
.ko-progress-circle {
	width:  $circle-size;
    height: $circle-size;
 
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 11;
    transform: translate(-50%, -50%);

	background-color: $circle-background;
	border-radius: 50%;
		.ko-progress-circle__slice, .ko-progress-circle__fill {
			width:    $circle-size;
			height:   $circle-size;
			position: absolute;
			-webkit-backface-visibility: hidden;
			transition: transform $transition-length;
			border-radius: 50%;
		}
		.ko-progress-circle__slice {
			clip: rect(0px, $circle-size, $circle-size, $circle-size/2);
			.ko-progress-circle__fill {
				clip: rect(0px, $circle-size/2, $circle-size, 0px);
				background-color: $circle-color;
			}
	}
	.ko-progress-circle__overlay {
        display:flex;
        align-items:center;
        text-align:center;
        justify-content:center;
		width:       $inset-size;
		height:      $inset-size;
		position:    absolute;
		margin-left: ($circle-size - $inset-size)/2;
		margin-top:  ($circle-size - $inset-size)/2;

		background-color: $inset-color;
		border-radius: 50%;
	}

	$i: 0;
	$increment: 180deg / 100;
	@while $i <= 100 {
		&[data-progress='#{$i}'] {
				.ko-progress-circle__slice.full, .ko-progress-circle__fill {
          transform: rotate($increment * $i);
				}	
				.ko-progress-circle__fill.ko-progress-circle__bar {
					transform: rotate($increment * $i * 2);
				}
      $i: $i + 1;
		}
	}
}
    .progress-txt {
        color: #ffffff;
        // font-family: "Poppins Medium";
        font-size: 8px;
        font-weight: 500;
    }

    .close-icon {
        opacity: 0;
        top: 0;
        right: 0;
        transform: translate(50%,-50%);
        z-index: 11;
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: #00aeef;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        & span {
            background-color: #ffffff;
            width: 2px;
            height: 9px;
        }
        & span:first-child {
            transform: translateX(1px)  rotate(-45deg);
        }
        & span:last-child {
            transform: translateX(-1px) rotate(45deg);
        }
    }
}


.dropzone {
    min-width: 100%;
    background: rgba(246, 246, 246, .5);
    border-radius: 0px;
    border: 2px dashed rgb(0, 135, 247);
    border-image: none;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width:767px) {
        min-height: auto !important;
        padding: 8px !important;
            
    }
    .dz-message {
        @include bp('md') {
            margin: {
                top: 0rem;
                bottom: 0rem;
            }
        }   
    }
}


// Profile upload

.profile-upload {
    min-width: 183px !important;
    max-width: 183px !important;
    min-height: 236px !important;
    @media screen and (max-width:767px) {
        min-width: 113px !important;
        max-width: 113px !important;
        min-height: 140px !important;
            
    }
    margin: 0 !important;
    .dz-preview {
        width: 100% !important;
        height: 100%;
    }
    .dz-message {
        height: 100%;
    
    }
    .msg-wrp {
           
    @media screen and (max-width:767px) {
     
        min-height: 123px !important;
            
    }
        flex-direction: column !important;
    }
    #previews1 {
        height: 100%;
        .profile-img {
            width: 100% !important;
            height: 100% !important;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.gs-uploadimg-text {
    font-size: 12px;
    line-height: 20px !important;
    color: #b4b4b4;
    font-weight: 400;
    margin-bottom: .5rem;
}