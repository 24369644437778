.candidant {
    @media screen and (max-width: 1439px){
        
        overflow-x: scroll;
    }
    &__img-info {
        min-width: 240px !important;
    }
    &__place {
        min-width: 125px;
    }
    &__date {
        min-width: 107px;
    }
    &__response {
        min-width: 160px;
    }
    &__source {
        min-width: 100px;
    }
}
.text-wrp-crop {
    width: 120px;
    .tbl-txt {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
.close {
    cursor: pointer;
}

.banner-cv {
    &__drop {
        height: 35px !important;
        min-height: 1.5em !important;
        padding-left: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        outline: none !important;
        background-color: transparent !important;
        border: none !important;
    }
    .dropdown-icon {
        color: #ffffff !important;
        right: -.2rem !important;
    }
    .ui.selection.dropdown .menu>.item {
        font-size: 12px;
        line-height: 24px;
    }
}
.dots-interview {
    top: 80% !important;
}

.drop-screch {
    width: max-content !important;
    min-width: max-content !important;
    // left: 50% !important;
    // transform: translateX(-50%) !important;
}

.w-230 {
    width: 230px;
}