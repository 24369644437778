.notifications-dash {
    &__head {
        display: flex;
        justify-content: space-between;
    }
    .icon-angle-down {
        transform: .6s ease-in-out transform;
    }
}

.date-filter-btn {
    position: relative;
    .date-filter {
        border: 2px solid rgba(100, 100, 100, .6)!important;
        cursor: pointer;
        outline: none;
        width: 173px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
            color: #00aeef;
        }
    }
    .d-12 {
        color: #000000;
        font-size: 12px;
        font-weight: 500;
        padding-bottom: 12px;
    }
    .date-active {
        display: block !important;
        transition: .6s ease-in-out all;
    }
    .date-filter-container {
        display: none;
        transition: .6s ease-in-out all;
        margin-top: 8px;
        padding: 20px 16px;
        position: absolute;
        right: 0;
        width: 370px;
        box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.11);
        border-radius: 5px;
        background-color: #ffffff;
    }
    .date-w130 {
        width: 50% !important;
    }
    .quick-select {
        margin-top: 22px;
    }

    .notif-icon{
        width: 14px !important;
        height: 14px !important;
        align-self: center !important;
        vertical-align: middle !important;
    }

    .date-btn {
        outline: none;
        padding: 8px;
        color: #494949;
        font-size: 12px;
        font-weight: 500;
        border-radius: 5px;
        border: 1px solid #cccccc;
        background-color: #ffffff;
        &:hover {
            border-color: #00aeef;
            color: #00aeef;
        }
        &:focus{
            border-color: #00aeef !important;
            outline: none !important;
            color: #00aeef;

        }

    }
    .footer-date-filter {
        margin-top: 32px;
    }
}

//Notification Detainls

.notification-details {
    .pt-32 {
    }
    &__head {
        padding-top: 32px;
        display: flex;
        justify-content: space-between;

    }
    .clear-all {
        cursor: pointer;
        color: #00aeef;
        font-size: 14px;
        font-weight: 600;
    }

    &__body {
        margin-top: 20px;
        box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        background-color: #ffffff;
        & .not-found {
            display: none;
            font-size: 14px;
            padding: 16px 32px;
            text-align: center;
        }
        & .list-item {
            border-bottom: 1px solid #fafafa;
            display: flex;
            justify-content: space-between;
            padding: 16px 32px;
            .list-item__text{
                margin-top: 4px;
                color: #1e1e1e;
                font-size: 12px;
                font-weight: 400;
            }
            .notification-date {
                // display: none;
                &__time {
                    width: 14px;
                    height: 14px;
                }
                &__close {
                    display: none;
                    width: 14px;
                    height: 14px;
                }
                &__wrp {
                   display: flex;
                   align-items: center;
                }
                &__txt {
                    color: #ababab;
                    font-size: 12px;
                    font-weight: 500;
                    margin-right: 32px;
                }
            }

            &:hover ,&:focus {
                .js-notification-close {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}
.js-notification-close {
    cursor: pointer;
}

.rotate-90 {
    transform: rotate(180deg) !important;
    transition: .5s ease-in-out transform;
}