.gs-home-banner {
    position: relative;
    height: 480px;
    overflow: hidden;
    
    @include bp("md") {
        height: 100vh;
    }

    &__content {
        position: relative;
        height: 100%;
        background: linear-gradient(to top, rgba(2, 95, 188, .78) 0%, rgba(24, 130, 199, .78) 0%, rgba(2, 95, 188, .78) 100%);
        color: $white;
    }
}

#myvideo {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    transform: translateX(-50%) translateY(-50%);
}

#banner-video {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    vertical-align: baseline;
}

.gs-banner-heading {
    font-size: 26px;
    line-height: 36px;
    font-weight: $fnt-bold;
    
    @include bp("md") {
        font-size: 62px;
        line-height: 81px;
    }
    @include bp("xl") {
        font-size: 74px;
        line-height: 91px;
    }
}

.gs-banner-des {
    font-size: 14px;
    line-height: 24px;
    @include bp('mobile') {
        max-width: 300px;
        margin: 0 auto;
    }
    @include bp("md") {
        font-size: 18px;
        line-height: 30px;
        max-width: 517px;
        margin: 0 auto;
    }

    @include bp("lg") {
        font-size: 20px;
        line-height: 32px;
    }
}

.play-btn {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: $white;
    
    @include bp("md") {
        height: 48px;
        width: 48px;
    }
}

.play-icon {
    position: relative;
    left: 2px;
    height: 10px;
    width: 10px;
    @include bp("md") {
        height: 20px;
        width: 20px;
    }
}

.play-text {
    @extend .fnt-sm;
    font-weight: $fnt-semi;
}