.table-scroll {
    overflow-x: auto;
    height: 100%;
}

.table-candiate-dashboard {
    width: 100%;

    th {
        text-transform: capitalize;
        color: #121212;
        font-size: 12px;
        line-height: 22px;
        font-weight: 700;
        padding: 20px 10px;
        text-align: left;
        // text-align: center;

        span {
            text-transform: lowercase;
        }

        &:first-of-type {
            text-align: center;
        }
    }

    td {
        font-size: 12px;
        line-height: 24px;
        font-weight: 500;
        padding: 12px 10px;
        vertical-align: middle;
        border: 0;

        @media (max-width: 1199px) {
            // min-width: 150px;
            padding: 10px;
        }
        

        &:first-of-type {
            @media (min-width: 1200px) {
                min-width: 260px;
                
            }

            @media (max-width: 1199px) {
                min-width: 260px;
                padding: 20px 10px;
            }
        }

      
        .comments {
            float: left;
            font-size: 12px;
            line-height: 22px;
            opacity: 0.3;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            margin-right: 15px;

            @media (min-width: 768px) {
                margin-right: 10px;
            }

            @media (min-width: 1200px) {
                margin-right: 15px;
            }

            @media (min-width: 1300px) {
                margin-right: 10px;
            }

            &.active {
                color: rgba(0, 0, 0, 0.85) !important;
                opacity: 1 !important;
                width: 100px; 
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            
        }

        &.active {
            opacity: 1;
        }

       

        .cv-screning-dropdown {
            .ui.fluid.dropdown {
                width: 125px;
                height: 36px!important;
            }
            &__head {
                margin-right: 10px;
            }

           &__list {
               
               outline: 0;
               font-size: 12px !important;
               box-shadow:  none !important;
               border: 1px solid #00aeef !important;
               border-radius: 5px !important; 
               padding: .5rem !important;
               background-color: transparent !important;

                .menu {
                    span {
                        font-size: 12px !important;
                        font-weight: 400 !important;
                    }
                }
              
           }
        }
    }
  
    tbody {
        // box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
        background-color: #ffffff;

        .w-20 {
            @media (min-width: 768px) {
                min-width: 125px;
            }

            @media (min-width: 1200px) {
                min-width: 100px;
                width: 20% !important;
            }

            &--dropdown {
                min-width: 150px;
            }
        }

        tr {
            border-bottom: 1px solid rgba(151, 151, 151 , 0.1) !important;
            transition: 0.2s ease-in-out;

            &:hover {
                box-shadow: 0 2px 17px 3px rgba(0, 0, 0, 0.09);
                border-color: #ffffff;
            }

            &.active {
                border-color: #ffffff;
            }

            &:last-of-type {
                

                td {
                    &:first-of-type {
                        border-bottom-left-radius: 9px;
                    }

                    &:last-of-type {
                        border-bottom-right-radius: 9px;
                    }
            
                }
        
            }

            &:first-of-type {
                border: 0;
                td {
                    &:first-of-type {
                        border-top-left-radius: 9px;
                    }

                    &:last-of-type {
                        border-top-right-radius: 9px;
                    }
            
                }
        
            }

           
        }
    }
}

.candiate-profile {
    display: inline-block;
    margin-left: 20px;

    &__img {
       float: left;

       img {
           width: 35px;
           height: 35px;
           border-radius: 50%;
           object-fit: cover;

           @media (min-width: 992px) {
               width: 40px;
               height: 40px;
           }
       }
    }

    &__info {
       float: left;
       margin-left: 15px;
       width: 140px;

       .candiate-name {
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            line-height: 24px;
            font-weight: 600;
       }

       .candiate-number {
            color: rgba(0, 0, 0, 0.85);
            font-size: 12px;
            line-height: 22px;
            font-weight: 400;
       }
    }
}
.dropdown-dots {
    float: right;
    cursor: pointer;
   
    
    span {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #00acf5;
        margin-bottom: 5px;
        
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}