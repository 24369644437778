// Swiper-overide

.swiper-pagination {
    position: relative !important;
}

.swiper-pagination-bullet {
    background: #e5e5e5;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background: $theme-primary;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    margin: 0 .25rem;
}

.swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 0 !important;
}


.btn-arrow {
    font-size: 20px;
}

.button-next,
.button-prev,.award-prev, .award-next {
    display        : inline-block;
    position       : absolute;
    outline        : none;
    top            : 50%;
    margin-top     : 14px;
    z-index        : 10;
    cursor         : pointer;
    display        : flex;
    justify-content: center;
    align-items    : center;
    transform: translateY(-50%);
}

.button-prev, .award-prev {
    left     : 0;

    @include bp("md") {
        left      : -15px;
    }
    @include bp("lg") {
        left      : -20px;
    }
    @include bp("xl") {
        left      : -10px;
    }
    transition: cubic-bezier(0.95, 0.05, 0.795, 0.035) all .8s;
}

.button-next, .award-next {
    right      : auto;

    @include bp("md") {
        right     : -15px;
    }
    @include bp("lg") {
        right     : -20px;
    }
    @include bp("xl") {
        right     : -10px;
    }
    transition: cubic-bezier(0.95, 0.05, 0.795, 0.035) all .8s;
}

.award-next.btn-arrow.swiper-button-disabled,
.award-prev.swiper-button-disabled {
    z-index            : -1;
    transition         : cubic-bezier(0.95, 0.05, 0.795, 0.035) all .8s;

    i {
        color  : $white !important;
        opacity: 0.2;
    }
}

.gs-test-arrows {
    .swiper-button-disabled {
        z-index            : -1;
        transition         : cubic-bezier(0.95, 0.05, 0.795, 0.035) all .8s;
    
        i {
            color  : $black !important;
            opacity: 0.2;
        }
    }
}

.button-next.swiper-button-disabled, .award-next.swiper-button-disabled {
    z-index         : -1;
    transition      : cubic-bezier(0.95, 0.05, 0.795, 0.035) all .5s;
}


// awards-slider

.award-next.swiper-button-disabled, .award-prev.swiper-button-disabled {
    z-index: 10 !important;
}

.award-prev, .award-next {
    margin-top: 0;
}

.award-next {
    @include bp("md") {
        right: -1.2rem;
    }
    @include bp("xl") {
        right: 0;
    }
}

.award-prev {
    @include bp("md") {
        left: -1.2rem;
    }
    @include bp("xl") {
        left: 0;
    }
}

.swiper-pagination-awards {
    @extend .flex-center;
    // @include bp("md") {
    //     display: none;
    // }
    .swiper-pagination-bullet {
        background: rgba(8, 89, 166, .76);
        height: 6px;
        width: 6px;
        @include bp("md") {
            height: 8px;
            width: 8px;
        }
    }
    .swiper-pagination-bullet-active {
        background: $white !important;
        height: 8px;
        width: 8px;
        @include bp("md") {
            height: 10px;
            width: 10px;
        }
    }
}
.js-testimonial-slider {
    width: 100%;
    .swiper-slide {
        // display: flex;
    }
    // .gs-blockquote-card {
    //     display: flex;
    //     flex-direction: column;

    // }
    .author-info {
        flex: 1;
    }
}