.gs-side-filter {
    background: $white;
    padding: 1rem 1.2rem;
    border-radius: 8px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.04);
    transition: all ease .5s;

    @include bp("mobile") {
        display: flex;
        min-height: 62px;
        margin: {
            top: 1.5rem;
            bottom: 1.5rem;
        }
    }
    @include bp("md") {
        position: sticky;
        top: 6rem;
        padding: 1.5rem 1.2rem;
    }

    @include bp("xl") {
        width: 300px;
    }
    @media (min-width: 1300px) {
        width: 309px;
    }

    // Secondary dashboard
    &--sm {
        width: auto;
        padding: 2rem 2rem;

        .gs-side-filter__list__item {
            margin-bottom: 1.4rem;
        }
        .gs-side-filter__list__item.check .gs-side-filter__link--sm::after {
            width: 6px;
            height: 12px;
            left: 10px!important;
            top: 5px!important;
        }
    }

    &__list{
        counter-reset: my-number-counter;

        
        @include bp("mobile") {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        
        // Item
        &__item {
            @include bp("tablet") {
                display: flex;
                align-items: center;
            }

            @include bp("md") {
                margin-bottom: 1.8rem;
            }
            &:last-child {
                margin-bottom: 0;
            }
            
            &.active {
                .gs-side-filter__link{
                    color: rgba(0, 0, 0, 0.85);
                    font-weight: $fnt-semi;
                    opacity: 1;
                    &::before {
                        background: #2485e8;
                        color: $white;
                    }
                    span {
                        @include bp("mobile") {
                            display: inline-block;
                        }
                    }
                }

                // LONGFORM
                .gs-sublist {
                    @include bp("md") {
                        display: block;
                    }
                }

            }
        
            //Form fileds are checked
            &.check {
                .gs-sublist {
                    display: none;
                    transition: all ease .5s;
                }
                .gs-side-filter__link {
                    color: #42a958 !important;
                    &::before {
                        background: #42a958 !important;
                        content: "" !important;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        left: 12px!important;
                        top: -7px!important;
                        width: 6px;
                        height: 12px;
                        border: solid #fff;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                        @include bp("md") {
                            width: 7px;
                            height: 15px;
                            left: 12px!important;
                            top: 3px!important;
                        }
                    }
                }

                // hide text in mobile
                span {
                    @include bp("mobile") {
                        display: none !important;
                    }
                }
            }
        }
    }

    .gs-sublist {
        display: none;
    }

    &__link {
        @extend .fnt-md;
        color: $text-primary85;
        font-weight: $fnt-medium;
        opacity: .3;
        position: relative;
        padding-left: 2.5rem;
        pointer-events: none;
        @include bp("mobile") {
            span{
                display: none;
                max-width: 80px;
            }
        }
        @include bp("tablet") {
            font-size: 12px !important;
            line-height: 20px !important;
        }
        @include bp("md") {
            padding-left: 2.6rem;
        }
        @include bp("lg") {
            padding-left: 3rem;
        }
        &::before {
            content: counter(my-number-counter);
            counter-increment: my-number-counter;
            @extend .fnt-sm;
            position: absolute;
            @extend .flex-center;
            left: 0;
            top: 50%;
            height: 28px;
            width: 28px;
            transform: translateY(-50%);
            border-radius: 50%;
            background-color: #b9bbbb;
            @include bp("md") {
                height: 31px;
                width: 31px;
            }
        }

        // Secondary

        &--sm {
           font-size: 14px !important;
           line-height: 24px !important;
           padding-left: 2.5rem;
           opacity: 1;
           font-weight: 400;

           &::before {
                @include bp("md") {
                    height: 24px;
                    width: 24px;
                    font-size: 12px !important;
                    line-height: 24px !important;
                    top: 0px !important;
                    color: $white;
                }
           }
        }
    }
}

// mobile css info

.js-side-filter {
    @include bp("mobile") {
        position: sticky;
        top: 2.6rem;
        z-index: 100;
    }
}


//Long application

.gs-steps-container {
    border-radius: 8px 8px 0  0;
    padding-bottom: 2rem;
    box-shadow: 0 2px 9px 3px rgba(0,0,0,.04);
    @include bp("md") {
        padding-bottom: 3rem;
    }
    @media (min-width: 1300px) {
        margin-left: 1rem;
    }

    &--btm {
        padding-bottom: 2rem;
    }
}

.gs-long-list {
    &__item {
        display: flex;
    }
    .gs-side-filter__link {
        &::before {
            @include bp("md") {
                top: -4px;
                transform: translateY(0);
            }
        }
    }
}

.gs-sublist {
    margin-top: .4rem;
    &__item {        
        font-size: 12px !important;
        line-height: 24px !important;
        color: $text-primary85;
        font-weight: $fnt-reg;
        margin-bottom: .4rem;
    }
}


