.itemActive {
    display: block !important;
}
.item-show {
    display: none;
}
.edit-form {
    .gs-steps-container {
        box-shadow: none;
    }
}
.page-links {
    color: #00acf5 !important;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.btn-blue-border {
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #00adf2;
    background-color: transparent;
    padding: 8px 26px;
    color: #00acf5;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    &:focus {
        outline: none;
    }
}
.view-job-descript {
    .arrow {

        border: solid #00acf5;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        margin-bottom: 1px;
      }
      
      
      .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
}
.edit-job {
    margin-top: 40px;
    padding: 32px;
    box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    background-color: #ffffff;
    &__banner {
        display: flex;
        justify-content: center;
        border-radius: 8px;
        background-color: #1a82eb;
        padding: 24px;
        margin-bottom: 20px;
    }
    
    &__details {
        margin-top: 18px;
        flex-wrap: wrap;
    }
    &__item {
        margin-bottom: 8px;
        margin-right: 32px;
        @media screen and (min-width: 1204px){
            margin-right: 54px;
            
        }
        p {
            font-size: 14px;
        }
    }
    .edit-job-container {
        max-width: 747px;
        width: 100%;
        color: #ffffff;
        .drp-plachlder {
            color: #ffffff !important;;
        }
    }
    h1 {
        color: #ffffff;
        // font-family: "Poppins Semi Bold";
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.83px;
    }
    
    h4 {
        color: #000000;
        // font-family: "Poppins Semi Bold";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.67px;
        line-height: 32px;
    }
    h5 {
        color: #000000;
        // font-family: "Poppins Medium";
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
    }
    .job-fnt-14 {
        color: #000000;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
    }
    .edit-head {
        padding-bottom: 4px;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.42px;
    }

    .edit-txt {
        // font-family: "Poppins Medium";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.58px;
    }
    .criteria-cont {
        margin: 20px 0;
        .criteria-wrp {
            margin-top: 12px;
            display: flex;
            flex-wrap: wrap;
    
            &__item {
                display: flex;
                margin-right: 40px;
                margin-bottom: 12px;
            }
            .criteria-txt {
                color: #000000;
                // font-family: "Poppins Light";
                font-size: 14px;
                font-weight: 300;
                line-height: 26px;
            }
        }
    }
    .responsibality-list {
        list-style-type: disc;
        padding-left: 20px;
    }
}

.preave-team-wrp {
    display: flex;
    flex-wrap: wrap;
    h6 {
        color: #152935;
        font-size: 14px;
        font-weight: 600;
    }

    p {
        color: #152935;
        font-size: 12px;
        font-weight: 400;
    }
    &__member {
        margin-top: 28px;
        margin-right: 2rem;
    }
}

.close-modal {
    &__content {
        width: 444px;
        border-radius: 8px;
        background-color: #ffffff;
        padding: 32px 40px;
    }
    &__input {
        outline: none;
        font-family: 'Poppins', sans-serif !important; 


        border-radius: 4px;
        border: 1px solid #b4b4b4;
        margin-top: 20px;
        margin-bottom: 28px;
        padding: 15px 16px;

       
    }
    &__input::placeholder {
        font-family: 'Poppins', sans-serif !important; 

        color: #b4b4b4;
        // font-family: Poppins;
        font-size: 12px;
        font-weight: 400;

    }
    b {
        font-weight: 600;
        text-align: center;
    }
    &__h5 {
        width: 100%;
        text-align: center;
        color: #212121;
        // font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
    }
}
.delete-model-content {
    width: 427px;
}
.btn-yesno {
    width: 149px;
}
.prompt-container {
    display: none;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    position: absolute;
    top: 0px;
    min-width: 706px;
    box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.11);
    border-radius: 1px;
    border: 2px solid #77ca64;
    background-color: #f6fff5;
    padding :20px 32px;
    &__close {
        display: flex;
        align-items: center;
    }
    &__close span {
        font-size: 24px;
        
        cursor: pointer;
    }
}
.btn-wrper-discription {
    .btn-img {
        img{
            width: 18px;
            height: 18px;
            margin-bottom: 2px;

        }
    }
}
.btn-cs {
    width: 138px;
}
/* Firefox */
.js-filled-img {
    display: none;
}