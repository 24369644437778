/* Breakpoints */
// $xs: 320px;
// $sm: 576px;
// $md: 768px;
// $mobile: 767px;
// $lg: 992px;
// $tablet: 991px;
// $desktop: 1024px;
// $xl: 1200px;
// $sxl: 1600px;
// $mxl: 1900px;
// Mid Points 

@mixin bp($point) {
  @if $point== "md" {
    @media (min-width: $md) {
      @content;
    }
  }

  @if $point== "lg" {
    @media (min-width: $lg) {
      @content;
    }
  }

  @if $point== "xl" {
    @media (min-width: $xl) {
      @content;
    }
  }
  
  @if $point== "$sxl" {
    @media (min-width: $sxl) {
      @content;
    }
  }

  @if $point== "mobile" {
    @media (max-width: $mobile) {
      @content;
    }
  }

  @if $point== "tablet" {
    @media (max-width: $tablet) {
      @content;
    }
  }
}


@mixin input-box-shadow {
  box-shadow: 0 0 8px rgba(239, 64, 35, 0.2) !important;
}

/* antialiasing mode font rendering*/

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/*border with gradient*/

@mixin fonts($size-xs, $size-md, $size-lg, $case, $f-family) {
  $line-diff: 0.5rem;
  /* font-weight: $font-wt;*/
  text-transform: $case;
  font-family: $f-family;
  font-size: ms($size-xs);
  line-height: $size-xs + $line-diff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: $md) {
    font-size: ms($size-md);
    line-height: $size-md + $line-diff;
  }

  @media (min-width: $lg) {
    $line-diff: 0.6rem;
    font-size: ms-function($size-lg);
    line-height: $size-lg + $line-diff;
  }
}

@mixin fonts-px($size-xs, $size-md, $size-lg, $case, $f-family) {
  $line-diff: 8px;
  /* font-weight: $font-wt;*/
  text-transform: $case;
  font-family: $f-family;
  font-size: $size-xs;
  line-height: $size-xs + $line-diff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: $md) {
    font-size: $size-md;
    line-height: $size-md + $line-diff;
  }

  @media (min-width: $lg) {
    $line-diff: 8px;
    font-size: $size-lg;
    line-height: $size-lg + $line-diff;
  }
}