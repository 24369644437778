.msg{
    box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    background-color: #ffffff;
    margin: 5px;
    padding: 0 !important;
}
.msg__img{
    width: 32px;
    height: 32px;
    margin-top: 5px;
}

.msg-pro__img{
    @extend .msg__img;
    margin-top: 0;
}
.p-15{
    padding: 15px;
    display: flex;
    flex-direction: row;
}
.msg__top{
    position: relative;
}

.msg__delete{
    cursor: pointer;
}
.msg__name{
    @include fonts(10px, 500, #6e6e6e, $font-poppins);
    cursor: pointer;


}

.msg__title{
    @include fonts(14px, 600,#131313, $font-poppins);
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.msg__content{
    @include fonts(12px, 400, #131313, $font-poppins);
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    cursor: pointer;
}

.msg__time{
    @include fonts(10px, 500, #6e6e6e, $font-poppins);
    // position: absolute;
    right: 0;
    cursor: pointer;

}
.msg__head{
    @include fonts(16px, 600, #131313, $font-poppins);
}

.msg__attach{
    @extend .msg__time;
    align-self: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;

}

.msg__body{
    @include fonts(14px, 400, #5a5a5a, $font-poppins);
}

.attach{
    @include fonts(12px, 600, #131313, $font-poppins);
}

.msg__li{
    display: flex;
    flex-direction: row;
    width: 100% !important;
}

.msg__set{
    margin: 0 5px;
    width: 100%;
}

.msg__timeset{
    position: absolute;
    right: 0;
    bottom: 0;
}

.set__top{
    position: relative;
    display: flex;
    flex-direction: row;
}

.set__bottom{
    @extend .set__top;
}

.img__attach{
    width: 11px;
    height: 13px;
    margin-left: 2px;
}

.msg-pro__details{
    @extend .msg__name;
    cursor:  pointer !important;
}

.msg-pro__name{
    cursor: pointer;
    @include fonts(14px, 600,#131313, $font-poppins);
    cursor: pointer !important;
}

.msg-nav{
    @include fonts(14px, 500,#4d4d4d, $font-poppins);
    padding: 10px !important;
    border-bottom: 1px solid #eff0f1 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    background: transparent !important;
}


.b-r{
    border-right: 2px solid #f7f7f7;
}
.b-b{
    border-bottom: 2px solid #f7f7f7;
}
@mixin changing-li{
    background: #f6f6f6;
    cursor: pointer;
    h4{
        color: #1260ad;
    }
}
.msg-hover{
    &:hover{
        @include changing-li();
    }

    &:focus{
        @include changing-li()
    }

    &:active{
        @include changing-li()
    }


}

.msghoverEffect {
    
    background: #f6f6f6;
    cursor: pointer;
    h4{
        color: #1260ad;
    }

}
.msg-pro__set{
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    cursor: pointer !important;
}

.f-right{
    float: right;
    
}

.mrgn-btm{
    margin-bottom: 20px;
}
.flex-row{
    display: flex;
    flex-direction: row;
}
.msg__reply{
    margin-right: 10px;
    cursor: pointer;
}
.mt-15{
    margin-top: 15px;
} 

.mrgn-left-neg{
    margin-left: -15px;
}

.ml-15{
    margin-left: 10px;
    margin-right: 10px;
}
.p-body{
    
    padding: 25px 10px;
}

.file__row{
    margin: 20px 0 20px 20px;
    justify-content: space-around;
    // width: fit-content;
}

.bb-0{
    border-bottom: 0 !important;
}

.gs-input-search{
    &--msg__search{
        padding: 10px;
       
        .form-input {
            padding-right: 20px !important;
        }
    }
}
.full-width{
    width: 100% !important;
    border: 2px solid #cfcfcf !important;
    padding: 10px !important;
    height: 45px !important;
    max-width: 100% !important;
}

.full-width::placeholder{
    color: #ccc !important;
}

.btn__dnld{
    width: 30px !important;
    height: 30px !important;
    align-self: top;
    margin-left: 30px;
} 
.download{
    align-self: center;
}
.down{
    width: fit-content !important;
    border: none !important;
    padding: 0 !important;
    cursor: pointer;
    background: transparent !important;
    // background-image: url(./img/candidate-profile/download-btn.png);
}

.down:active, .down:focus 
{
    border: none !important;
    outline: none !important;
}

.msg__pdf{
    width: 20px;
    height: 25px;
    margin-left: 10px;
    align-self: center;
}
.mrgn-tp{
    margin-left: 20px !important;
    margin-right: 20px;
}
.p-2r{
    padding: 2rem;
}

.team-img-wrp{
    &--msgs{
            left: 50px !important;
            top: 37px;
            width: 260px !important;
            box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.25) !important;
    }
    
}

.msg-pop-card::after{
    border: 0 !important;
}

.team-img-wrp::after {
    &--msgs{
        display: none !important;
        padding: 10px !important;

    }

}
.applied{
    width: 55%;
    margin-top: -5px;

}

.stage{
    margin-left: 25px !important;
    display: flex;
    flex-direction: column;
}
.pop-sub-head{
    @include fonts(12px, 400, #949494, $font-poppins);
}
.pop-sub-content{
    @include fonts(12px, 500, #292929, $font-poppins);
}

.mbl{
    @include fonts(10px, 500, #717271, $font-poppins);
}

.popup-content{
    align-items: baseline;
    padding: 10px;
}
.bb-0{
    border-bottom: none !important;
    padding: 0px !important;
}

.bb-1{
    border-bottom: 1px solid #eaeaea;
    padding: 10px !important;
}
.border-bottom-grey{
    border-bottom: 1px solid #f5f5f5 !important;

}
.pop-buttons{
    // align-self: flex-end;
    float: right;
    position: relative;
    margin-right: 10px;
}

.v-top{
    vertical-align: top !important;
    width: 30px !important;
    height: 30px !important;

    // margin-top: -10px;
}

.team-img{
    &--msg{
        margin-top: -10px;
        margin-left: -2px !important;
    }
}

.team-member-info{
    &--msgs{
        cursor:  pointer !important;
    }
}

.no-border{
    border: none !important;
}
.w-auto{
    width: auto !important;
}

.wdth-100{
    width: 100% !important;
}

.selected-roles{
    @include fonts(18px, 600, #000, $font-poppins);
    letter-spacing: 1px;
    text-transform: uppercase;
}