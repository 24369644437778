
.modal {
    @include bp("mobile") {
        top: 0 !important;
    }
}


.modal-dialog-centered {
    // min-height: calc(100% - 4rem);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-dialog {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    @include bp("mobile") {
        margin: {
            left: 1rem;
            right: 1rem;
        };
    }
}

.modal-backdrop.show {
    opacity: .7;
}


// Modal Large

.modal-lg {
    @include bp("md") {
        max-width: 700px;
    }
    @include bp("lg") {
        max-width: 800px;
    }
    @include bp("xl") {
        max-width: 900px;
    }

    .modal-content {
        height: calc(100% - 12%);
    }

    .modal-body {
        overflow-y: auto;
        overflow-x: hidden;
    }
}


// Modal Small

.modal-sm {
    @include bp("md") {
        max-width: 500px;
    }
    @include bp("lg") {
        max-width: 600px;
    }
    .gs-block-list__item {
        @extend .fnt-sm;
    }
}

// Modal X-Small
.modal-xs {
    @include bp("md") {
        max-width: 400px;
    }
    @include bp("lg") {
        max-width: 492px;
    }
}

// Modal Inner content

.modal-content {
    width: 100%;
    padding: 1.2rem 1.2rem;
    border-radius: 8px;
    border: none;
}

.btn-close {
    opacity: 1 !important;
    cursor: pointer;
}

.modal-body, .modal-header {
    @include bp("mobile") {
        padding: 0 !important;
    }
}

// Note popup

.gs-notecontent-info {
    padding: 1.5rem;
    display: block;
    @include bp("md") {
        padding: 2rem 2rem;
    }
}

.note-title {
    font-size: 20px;
    line-height: 32px;
    font-weight: $fnt-semi;
}

// Upload resume popup

// .gs-popup-upload {
//     margin-top: .8rem !important;
//     @include bp("md") {
//         min-height: 197px;
//         overflow-y: scroll;
//     }
// }