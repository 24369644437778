

.form-right-wrapper {
    box-shadow: 0 2px 9px 3px rgba(0,0,0,.04);
    border-radius: 8px;
    @media (min-width: 1300px) {
        margin-left: 1rem;
    }
}

.gs-form-panel {
    // height: 800px;
    padding: 1.8rem 1.4rem;
    background: $white;
    border-bottom: 1px dashed rgba(151, 151, 151, .2);
    border-radius: 8px;

    &:last-child {
        border-bottom: none;
    }
    
    @include bp("md") {
        padding: 2rem 2rem 1rem 2rem;
    }
    
    @include bp("lg") {
        padding: 2.5rem 2.5rem 1rem 2.5rem;
    }
    
    @include bp("xl") {
        padding: 2.5rem 5rem 1rem 5rem;
    }

    @media (min-width: 1300px) {
        padding: {
            left: 6rem;
            right: 6rem;
        };
    }
    &--sm {
        padding: {
            left: 32px !important;
            right: 32px !important;
        };
        .gs-steps-categ::after {
            left: -2rem;
            width: calc(100% + 4rem);
        }
        

        .gs-side-filter__link::before {
            width: 24px !important;
            height: 24px !important;
        }
        // @include bp("md") {
        //     padding: 2rem 2rem 1rem 2rem;
        // }
        
        // @include bp("lg") {
        //     padding: 2.5rem 2.5rem 1rem 2.5rem;
        // }
        
        // @include bp("xl") {
        //     padding: 2.5rem 5rem 1rem 5rem;
        // }
    
        @media (min-width: 1300px) {
            padding: {
                left: 32px !important;
                right: 32px !important;
            };
        }
    }

    &__heading {
        @extend .fnt-lg;
        font-weight: $fnt-semi;
        margin-bottom: 1.2rem;
        @include bp("md") {
            margin-bottom: 2rem;
        }
    }

    .gs-form-group {
        margin-bottom: 1.5rem !important;
        @include bp("md") {
            margin-bottom: 2rem !important;
        }
    }

}


