// @font-face {
//      font-family: 'icomoon';

//      src: url('../fonts/icomoon/icomoon.eot?hggnzq');
//      src: url('../fonts/icomoon/icomoon.eot?hggnzq#iefix') format('embedded-opentype'),
//           url('../fonts/icomoon/icomoon.ttf?hggnzq') format('truetype'),
//           url('../fonts/icomoon/icomoon.woff?hggnzq') format('woff'),
//           url('../fonts/icomoon/icomoon.svg?hggnzq#icomoon') format('svg');
//      font-weight : normal;
//      font-style  : normal;
//      font-display: block;
// }



@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?7vfwjk');
    src:  url('fonts/icomoon.eot?7vfwjk#iefix') format('embedded-opentype'),
      url('fonts/icomoon.ttf?7vfwjk') format('truetype'),
      url('fonts/icomoon.woff?7vfwjk') format('woff'),
      url('fonts/icomoon.svg?7vfwjk#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  