.user-view-table {
    box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    background-color: #ffffff;
    &--details {
        th, td {
            padding: 14px 40px;
        }
    }

    &--history {
        th,td {
            padding: 14px 20px;

           
            &:first-child {
                padding-left: 40px;
            }
            // &:last-child{
            //     padding-left: 40px;
            // }
        }
    }
    
    tr {
        border-bottom: 1px solid #fafafa;
    }
    th {
        color: #2f2d2d;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
    }

    thead {
        background-color: #efefef;
    }
    .custom-check {
        padding-left: 2.5rem !important;
    }
    .fnt-13 {
        color: #212121;
        font-size: 13px;
        font-weight: 400;
        line-height: 1;
    }
    .float-rt {
        float: right;
    }

    .w-125{
        width: 124px;
    }
}