// cards

.gs-card {
    margin-bottom: 1.5rem;
    @include bp("md") {
        margin-bottom: 1.5rem;
    }
    @include bp("lg") {
        margin-bottom: 3.2rem;
        padding-left: 1rem;
    }
    @media (min-width: 1300px) {
        padding-right: 1rem;
        padding-left: 3rem;
    }

    &__view {
        height: 45px;
        width: 45px;
        border-radius: 50%;
        background: #1a82eb;
        @include bp("md") {
            height: 54px;
            width: 54px;
        }
        @include bp("lg") {
            height: 74px;
            width: 74px;
        }
        img{
            @include bp('mobile') {
                height: 25px !important;
                width: 25px !important;
            }
            @include bp('tablet') {
                height: 30px;
                width: 30px;
            }
        }
    }
}

.gs-card-title {
    font-size: 14px;
    line-height: 24px;
    font-weight: $fnt-semi;
    color: $text-primary85;
    margin-top: 1rem;
    margin-bottom: .5rem;
    @include bp("md") {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 1rem;
        margin-right: 6rem;
    }
    @include bp("lg") {
        font-size: 18px;
        line-height: 28px;
        margin-right: 2rem;
    }
    @include bp("xl") {
        margin-right: 4.5rem;
    }
}

.gs-card-des {
    @extend .fnt-md;
    color: $text-primary7;
    @include bp('mobile') {
        font-size: 13px !important;
        line-height: 24px;
    }
}