/* Variables*/

/* --------------------------------------------------*/

/* Media queries breakpoints*/

/* --------------------------------------------------*/

/* Medium screen / desktop*/

$xs: 320px;
$sm: 576px;
$md: 768px;
$mobile: 767px;
$lg: 992px;
$tablet: 991px;
$desktop: 1024px;
$xl: 1200px;
$sxl: 1300px;
$mxl: 1900px;

/* larger-screens */

$sxl-max: ($sxl - 1);

/** Extra large **/

$xl-max: ($xl - 1);

/* Large screen / wide desktop*/

$lg-max: ($lg - 1);

/* Medium screen / desktop*/

$md-max: ($md - 1);

/* Tiny screen / phone*/

$sm-max: ($sm - 1);


/* Calculate values */

/*font-families */

/*=============================================
                  Font Family
=============================================*/

/* Title Font Family*/

$font-family: 'Metropolis-Regular';
$font-poppins: 'Poppins', sans-serif;
/*=============================================
=                COLORS NAME                  =
=============================================*/

$white: #ffffff;

$blue: #0c3562;

$primary-blue: #005aa9;

$black : #000000;

$lght-blue: #1a82eb;
 

$color-blue: #00adee;


/*=============================================
=               THEME COLORS                  =
=============================================*/
/**
 * To change theme color add a new color variable in above block
 * and replace with that color variable in theme colors section
 */

/* Theme colors*/

$theme-primary: $blue;


// Text colors

$text-primary85: rgba(0, 0, 0, 0.85);

$text-primary7: rgba(0, 0, 0, 0.7);


/* heading */

$txt-heading: #212121;

//fonts

$fnt-light: 300;

$fnt-reg: 400;

$fnt-medium: 500;

$fnt-semi: 600;

$fnt-bold: 700;


// gradients

$primary-gradient: linear-gradient(to top,rgba(2, 95, 188, .75) 0%,rgba(24, 130, 199, .75) 0%,rgba(0, 121, 245, .75) 100%);



// background

$footer-circle: #f0f0f0;

$testimonial-card: #f4f9fe;


// border-radius

$img-radius5: 5px;

$brder-radius4: 4px;


// Transistion

$transition3: all .3s ease-out;


//input placeholder

$input-plchlder: #b4b4b4;


