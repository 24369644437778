// Form input 

.gs-input-search {
    position: relative;

    input {
        @include bp("mobile") {
            padding-left: 2.2rem !important;
        }
        @include bp("md") {
            width: 260px !important;
            max-width: 100%;
            padding-right: 2.2rem !important;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        
        @include bp("mobile") {
            margin-left: .8rem;
            left: 0;
            height: 15px;
            width: 15px;
        }
        
        @include bp("md") {
            right: 0;
            margin-right: 1rem;
        }
    }

    &--right{
        .gs-input-search__icon {
            margin-left: .8rem;
            left: 0;
            height: 15px;
            width: 15px;
        }
        input {
            padding-left: 2.2rem !important;
        }
    }
}

.form-search {
    @include bp("mobile") {
        width: 100%;
    }
}

// Form Input filled stylings

.form-label {
    font-size: 12px !important;
    line-height: 22px !important;
    font-weight: $fnt-medium !important;
    color: $black;
    margin-bottom: .5rem !important;
    
    @include bp("lg") {
        font-size: 14px !important;
        line-height: 24px !important;
    }
}

.gs-form-group {
    margin-bottom: 1.4rem !important;
    @include bp("md") {
        margin-bottom: 1.5rem !important;
    }
}

.form-input {
    border: 1px solid #b4b4b4 !important;
    border-radius: $brder-radius4;
    min-height: 48px !important;
    font-weight: 500 !important;
    -moz-appearance:textfield;
    
}


.drp-plachlder {
    font-size: 12px;
    line-height: 22px;
    font-weight: $fnt-medium;
}

.gs-textarea {
    resize: none !important;
    padding: 1rem !important;
}

// checkbox styling

.styled-checkbox {
    position: absolute;
    opacity: 0;
}
    
.styled-checkbox+label {
    position: relative;
    padding: 0;
    color: $text-primary85;
    font-weight: 400;
}
    
.styled-checkbox+label:before {
    content: '';
    margin-right: 8px;
    display: inline-block;
    vertical-align: text-top;
    width: 12px !important;
    height: 12px !important;
    background: 0 0;
    margin-top: 5px;
    border: 1px solid #00aeef !important;
    border-radius: 2px !important;
}

.styled-checkbox:checked+label:before {
    background: #00aeef !important;
}
    
.styled-checkbox:checked+label:after {
    content: '';
    position: absolute;
    left: 4px !important;
    top: 6px !important;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
}

// Radio buttons

.gs-grouped-fileds {
    @include bp("md") {
        padding-top: .2rem;
    }
    .ui.checkbox label:before {
        border: 1px solid #00adf2 !important;
        height: 13px !important;
        width: 13px !important;
        border-radius: 50%;
    }
    .ui.radio.checkbox input:checked~label:after {
        background-color: #00adf2 !important;
        height: 13px !important;
        width: 13px !important;
        border-radius: 50%;
    }
    .ui.radio.checkbox.checked .grouped-label{
        color: #090909!important;
    }
}

.gs-grouped-filed {
    margin-right: 1.6rem;
}

.grouped-label {
    font-size: 12px !important;
    line-height: 16px !important;
    padding-left: 1.4rem !important;
    color: $input-plchlder !important;
}


// Form fileds dashboard

.form-label-d {
    font-size: 12px !important;
    line-height: 22px !important;
    font-weight: $fnt-semi !important;
}

// Dashboard

.mandiatory {
    color: #ed1c25;
    display: inline-block;
    margin-left: .2rem;
}

@-moz-document url-prefix() {
    .salary-rang {
       
        -moz-appearance:textfield !important;
        width: 74% !important;
    }
    .salary-drop .dropdown {
        // min-width: 56px !important;
    }
    
  }

  .salary-drop .dropdown-icon {
    right: .6rem!important;
}


input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}