.map-wrp {
    .map-wrp-box {
        width: 100%;
        height: 100%;
    }
    .gmnoprint {
        opacity: 0;
        // @media screen and (min-width: 768px){
        // opacity: 1; 
            
        // }
    }
    .gm-control-active {
        opacity: 0;
    }
    &__container {
        position: relative;
        // height: calc(100vh - 4rem);
        min-height: 600px;
        height: 692px;
        width: 100%;
        
    @include bp("md") {
        height: calc(100vh - 84px);
        // height: 100%;

        }
    }
    #map-canvas {
        width: 100%;
        height: 100%;
    } 
    .nav-wrp {
        display: flex;
        flex-wrap: wrap;
    }
    .map-info {
        z-index: 99;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
        max-width: 421px;
        width: 90%;
        border-radius: 8px;
        @media screen and (min-width: 768px){
            top: 20%;
            left: 15%;
            transform: translateX(0);
            
        }
        // background-color: #1a82eb;
        
    }

    //radio style

    .nav-link.active,
    .nav-link {
        position: relative;
        // position: absolute;
        // left: -9999px;
    }
    .nav-link p,
    .nav-link.active p
    {
        line-height: 1;
        position: relative;
        padding-left: 18px;
        cursor: pointer;
        // line-height: 20px;
        display: inline-block;
        color: #ffffff;
        // font-family: "Poppins Medium";
        font-size: 12px;
        font-weight: 500;
        // line-height: 21px;
    } .nav-link.active p {
        font-weight: 700 !important;

    }
    .nav-link.active :before,
    .nav-link :before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 13px;
        height: 13px;
        border: 1px solid #ffffff;
        border-radius: 50%;
        background: transparent;
    }
    // .nav-link :after,
    .nav-link.active :after {
        content: '';
        position: absolute;
        width: 13px;
        height: 13px;
        background: #ffffff;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    .nav-link  :after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    .nav-link.active :after {
        opacity: 1;
        -webkit-transform: scale(.52);
        transform: scale(.52);
    }
    .location-wrp {
        display: flex;
        flex-wrap: wrap;
        // min-width: 421px;
        padding: 8px;
        border-radius: 8px;
        background-color: #1a82eb;
        @media screen and (min-width:768px){
            padding: 16;
            
        }
        .nav-item{
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
    .map-info-wrp {
        margin-top: 12px;
        padding: 24px;
        border-radius: 8px;
        background-color: #1a82eb;
    }
    .head-txt {
        color: #ffffff;
        // font-family: "Poppins Semi Bold";
        font-size: 22px;
        font-weight: 600;
        line-height: 31px;
    }
    .sub-text {
        color: #ffffff;
        // font-family: "Poppins Light";
        margin-top: 20px;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
    }
    .ico-wrp {
        display: flex;
        margin-top: 14px;
    }
    .ico-txt {
        
        color: #ffffff;
        // font-family: "Poppins Semi Bold";
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
    }
    .ico-txt {

        & a {

            color: #ffffff;
            // font-family: "Poppins Semi Bold";
            font-size: 12px;
            font-weight: 600;
            line-height: 24px;
        }
    }
   
}
.map-img {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100vh);
    @media screen and (min-width: 768px){
        height: calc(100vh - 83px);
        
    }
    // object-fit: cover;
    
    
}
.tab-content-img {
    .second-tab {
        display: none;
    }
}
.active-second-tab {
    display: block !important;
}