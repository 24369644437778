.gs-job-data {
    font-weight: $fnt-semi;
    @extend .fnt-md;
    letter-spacing: 0.67px;
    @include bp('mobile') {
        font-size: 12px !important;
        line-height: 24px !important;
        margin-top: 1rem;
    }

    &__item {
        margin-bottom: .5rem;
        @include bp('md') {
            margin-bottom: .8rem;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}