
.sticky-block {
    position: fixed;
    bottom: 2rem;
    z-index: 100;
    right: 1rem;
    .gs-filter {
        transition: all .5s ease;
        display: flex;
    }
}

.gs-filter {
    background: $lght-blue;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.11);
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.11);
    @extend .flex-center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    transition: all .5s ease;
    display: none;

    img {
        height: 24px;
        width: auto;
    }
}

.gs-filterd-content {
    white-space: nowrap;
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gs-select.show{
    display: none;
}

.gs-content-inner {
    max-height: 175px;
    overflow: scroll;
}

.gs-select-content {
    li {
        margin-bottom: .5rem;
    }
}

// Mobile filter open

.js-filterdropdown {
    position: fixed;
    right: 1rem;
    bottom: 0;
    background: $white;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.11);
    border-radius: 4px;
    transform: translateY(-35%);
    z-index: 10;
    visibility: none;
    transition: all .5s ease;
    opacity: 0;
    max-height: 100%;
    max-width: 100%;
    height: 220px;
    width: 200px;
    overflow-y: auto;

    &.open {
        transform: translateY(-40%);
        transition: all .5s ease;
        visibility: visible;
        opacity: 1;
    }
}

// mobile multi-selection

.gs-multiselect-container--mobile {
    .btn--selection {
        border: none;
        width: 100%;
        background:  #f9f9f9;
        margin-top: 4px;
        min-height: 40px;
    }
    .multiselect-selected-text {
        color: $black;
    }
    .dropdown-menu.show {
        position: relative !important;
        top: 0 !important;
        transform: translate3d(0px, 0, 0px) !important;
        width: 100%;
    }
    .multiselect-container {
        max-height: 100% !important;
        padding: {
            left: 1rem;
            right: 1rem;
        }
        border-radius: 0;
    }
}





// checkbox styles info

// .styled-checkbox {
//     position: absolute;
//     opacity: 0;
// }

// .styled-checkbox+label {
//     position: relative;
//     padding: 0;
//     color: $black;
// }

// .styled-checkbox+label:before {
//     content: '';
//     margin-right: 11px;
//     display: inline-block;
//     vertical-align: text-top;
//     width: 12px;
//     height: 12px;
//     background: 0 0;
//     margin-top: 2px;
//     border: 1px solid #00aeef;
//     border-radius: 2px;
// }

// .styled-checkbox:checked+label:before {
//     background: #00aeef;
// }

// .styled-checkbox:checked+label:after {
//     content: '';
//     position: absolute;
//     left: 4px;
//     top: 3px;
//     width: 4px;
//     height: 8px;
//     border: solid #fff;
//     border-width: 0 1px 1px 0;
//     transform: rotate(45deg);
// }