 /*
 * Container style
 */
.ps {
	overflow: hidden;
	overflow-anchor: none;
	-ms-overflow-style: none;
	touch-action: auto;
	-ms-touch-action: auto;
  }
  
  /*
   * Scrollbar rail styles
   */
  .ps__rail-x {
	display: none;
	opacity: 0;
	transition: background-color .2s linear, opacity .2s linear;
	-webkit-transition: background-color .2s linear, opacity .2s linear;
	height: 5px;
	/* there must be 'bottom' or 'top' for ps__rail-x */
	bottom: 0px;
	/* please don't change 'position' */
    position: absolute;
    right: 0;
  }
  
  .ps__rail-y {
	display: none;
	opacity: 0;
	transition: background-color .2s linear, opacity .2s linear;
	-webkit-transition: background-color .2s linear, opacity .2s linear;
	width: 5px;
	/* there must be 'right' or 'left' for ps__rail-y */
	right: 0;
	/* please don't change 'position' */
	position: absolute;
  }
  
  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
      cursor: pointer;
	display: block;
	background-color: inherit;
  }
  
  .ps:hover > .ps__rail-x,
  .ps:hover > .ps__rail-y,
  .ps--focus > .ps__rail-x,
  .ps--focus > .ps__rail-y,
  .ps--scrolling-x > .ps__rail-x,
  .ps--scrolling-y > .ps__rail-y {
	opacity: 0.6;
  }
  
  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
	// background-color: #eee;
	opacity: 0.9;
  }
  
  /*
   * Scrollbar thumb styles
   */
  .ps__thumb-x {
	background-color: #aaa;
	border-radius: 3px;
	transition: background-color .2s linear, height .2s ease-in-out;
	-webkit-transition: background-color .2s linear, height .2s ease-in-out;
	height: 2px;
	/* there must be 'bottom' for ps__thumb-x */
	bottom: 2px;
	/* please don't change 'position' */
	position: absolute;
  }
  
  .ps__thumb-y {
	background-color: #aaa;
	border-radius: 6px;
	transition: background-color .2s linear, width .2s ease-in-out;
	-webkit-transition: background-color .2s linear, width .2s ease-in-out;
    width: 3px;
    max-height: 100%;
	/* there must be 'right' for ps__thumb-y */
	right: 2px;
	/* please don't change 'position' */
	position: absolute;
  }
  
  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x {
	background-color: #999;
	height: 3px;
  }
  
  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
	background-color: #999;
	width: 3px;
  }
  
  /* MS supports */
  @supports (-ms-overflow-style: none) {
	.ps {
	  overflow: auto !important;
	}
  }
  
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.ps {
	  overflow: auto !important;
	}
  }
  