.activity-log-list {
    .list-item.active {
            
            background-color: #eaf5ff;
            border-bottom: 1px solid #ffffff;

            .notification-info {
                position: relative;
                &::before {
                    position: absolute;
                    content: '';
                    width: 6px;
                    height: 6px;
                    background-color: #f5585f;
                    border-radius: 50%;
                    left: -15px;
                    top: 7px;
                }

            }
    }   

  
}
.active-badge {
    margin-left: 16px;
    width: 24px;
    height: 24px;
    border-radius: 15px;
    background-color: #00acf5;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
    }
}
.fnt-10-gry {
    padding-left: 6px;
    color: #a2a2a3;
    font-size: 10px;
    font-weight: 500;
}