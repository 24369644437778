// side-navigation
.side-nav {
    z-index: 99;
    position: fixed;
    left: 0;
    top: 70px;
    bottom: 0;
    width: 250px;
    background-color: $primary-blue;
    padding-top: 2rem;
    overflow-y: auto;
}

.content-wrapper {
    position: relative;
    padding-left: 250px;
}

.dashboard-info {
    // height: 100vh;
    padding: {
        top: 2rem;
        bottom: 3rem;
        left: 3rem;
        right: 3rem;
    };
}

.sidenav-left {
    &__head {
        color: rgba(255, 255, 255, 0.3);
        text-transform: uppercase;
        @extend .fnt-xs;
        font-weight: $fnt-bold;
        padding : {
            left: 40px;
            right: 40px;
        }
    }
}

.side-nav-categ {
    margin-bottom: 1.2rem;
}

.start-list {
    margin-bottom: 15px;

    @media (min-width: 992px) {
        margin-bottom: 20px;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &__item {
        cursor: pointer;
       position: relative;
       z-index: 9;
       background-color: inherit;
       cursor: pointer;

        &:hover {
            background-color: #006dcd;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: -1px;
            width: 4px;
            height: 0;
            background: #ffffff;
            transition: 0.5s ease-in-out;
            z-index: 1;

        }

        // Active state

        &.active {
            background-color: #006dcd;
            &::before {
                height: 100%;
            }

            span {
                font-weight: $fnt-semi;
            }
        }

        &:hover::before {
            transform: translateY(0);
            height: 100%;
        }

        a {
            cursor: pointer;
            color: #ffffff;
            font-weight: 500;
            width: 100%;
            display: inline-block;
            text-transform: capitalize;
            @extend .fnt-sm;
            cursor: pointer;

            @media (max-width: 991px) {
                text-align: center;
            }
            
            @include bp("lg") {
                padding: 10px 40px;
            }

        }
       
        span {
            margin-left: 16px;

            
            @media (max-width: 991px) {
               display: none;
               opacity: 0;
               visibility: hidden;
            }

            @media (min-width: 768px) {
               margin-left: 10px;
             }
             
             @media (min-width: 992px) {
                margin-left: 16px;
             }
 
        }

        img {
            width: 18px;
            height: 18px;
            object-fit: contain;
        }

        .total-candiate {
            position: absolute;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 18px;
            padding: 0 10px;
            text-align: center;
            font-size: 10px;
            top: 50%;
            transform: translateY(-50%);
            right: 10%;
            @extend .fnt-xxs;
            @media (min-width: 1400px) {
                // font-size: 14px;
                // right: 15px;
            }
        }
    }
}