.search-role {
    height: 40px !important;
    border: 2px solid #a0a0a0 !important;
    background-color: transparent !important;
    padding-left: 2.2rem !important;
    @extend .fnt-xxs;
}

.role__name{
    @include fonts(13px, 600, #262626, $font-poppins);
}

.role__desc{
    @include fonts(13px, 500, #262626, $font-poppins);
}

.w-360{
    width: 360px;
}

.tbl-body {
    &--role{
        box-shadow: none !important;
        border-radius: 0 !important;
        background-color: #ffffff;
        border-bottom: 1px solid #f5f5f5 !important;
    }
    &--toplr{
        border-bottom: 1px solid #f5f5f5 !important;
        border-radius: 8px 8px 0 0 !important;
        box-shadow: none !important;
    }

    
}


.selected-font{
    @include fonts(12px, 500, #464646, $font-poppins);
}
.selected{
    background-color: #f3f3f3;
    border-radius: 5px;
    padding: 10px;
    width: fit-content;
}

.selected-container{
    margin-bottom: 30px;
}