// Tab section
.msg-modal-container {
    padding: 32px 40px;
    min-width: 540px;
    width: 100%;
}
.tab-section {
    // tabs
    &--candiate {
        padding: 0;
        padding-bottom: 1rem;
        margin-bottom: 10px;

        @media (min-width: 992px) {
            margin-bottom: 20px;
        }

        .nav-link  {
            position: relative;
            margin: 0;
            text-align: center;
            padding: 0 15px !important;

            @media (min-width: 992px) {
                padding: 0 1.2rem !important;
            }

            &::after {
                position: absolute;
                content: "";
                bottom: -1rem;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 3px;
                background-color: #00adf2;
                transition: .5s all;
                z-index: 1;
            }

            &.active::after {
                width: 100%;
            }
        }
    }

    .js-add-filter {
        color: #00acf5;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        outline: 0;
        background: inherit;
        border-radius: 5px;
        border: 2px solid #00adf2;
        padding: 4px 10px;
        transition: 0.3s ease-in-out;
       
    }
    .nav-item {
        margin-right: .5rem;
    }
}

//Active back Nav
.active-back {
    margin-bottom: 20px;

    @media (min-width: 992px) {
        margin-bottom: 35px;
    }

    &__link {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

       a {
        color: #00acf5;
        font-size: 12px;
        line-height: 22px;
        font-weight: 600;
        display: inline-block;

        @media (min-width: 992px) {
            font-size: 14px;
            line-height: 24px;
        }
       }
    }

    &__head {
        color: #393939;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        text-transform: capitalize;

        @media (min-width: 992px) {
            font-size: 24px;
            line-height: 34px;
        }
    }

    
}

.date-text {
    padding: 12px;
    width: 124px;
    font-family: "Poppins" !important;
    color: #b4b4b4;

}
.date-text::placeholder {
    color: #b4b4b4;
    font-family: "Poppins" !important;
    font-size: 12px;
    font-weight: 400;

}

.date-text:focus {
    outline: none;
}

.btn-blue-ef {
    outline: 0;
    border: 0;
    cursor: pointer;
    color: #ffffff;
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
    padding: 5px 15px;
    float: right;
    text-transform: uppercase;
    border-radius: 5px;
    position: relative;
    background-color: #00aeef;
    border: 1px solid #00aeef;
    transition: 0.3s ease-in-out;

    @media (min-width: 992px) {
        padding: 8px 25px;
        font-size: 14px;
        line-height: 24px;
    }

    &:hover {
        background-color: transparent;
        color: #00aeef;
        
    }

    &:focus {
        outline: 0;
    }
}
.sort-up-down-icon {
    height: 10px;
    width: 6px;
}

.gry-12 {
    color: #9a9a9a !important;
    font-size: 12px;
    font-weight: 500;
}
.candidate-model {
    .head-txt {
        color: #0d5cab;
        // font-family: "Poppins Semi Bold";
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
    }

    .close-icon {
        span {
            height: 16px;
            width: 2px;
            background-color: #616161;
            &:first-child{
                transform: rotate(45deg);
            }
        }
    }

    .mdl-textarea {
        outline: none;
        width: 100%;
        height: 104px;
        border-radius: 4px;
        border: 1px solid #cccccc;
        &::placeholder {
            color: #b4b4b4;
            font-family: "Poppins" !important;
            font-size: 12px;
            font-weight: 400;
        }
    }

    .sub-txt-11 {
        color: #655e5e !important;
        font-size: 11px !important;
        font-weight: 500 !important;
        padding-bottom: 7px;
    }

    .msg-input {
        min-height: 40px !important;

        & input {
            border: none !important;
        }
    }
    .mail-copy {
        align-self: center;
        padding-right: 12px;
    }
    .cpy-text {
        cursor: pointer;
        color: #00aeef;
        font-size: 12px;
        font-weight: 600;
    }
    .js-cc-input,
    .js-bcc-input {
        display: none ;
    }
    .copy-filter {
        p,.filter-drp-img__img {
            display: none;
        }

    }
    .filter-drp-img {
        align-items: center;
        &__img {
            width: 32px;
            height: 32px;
        }
        &__info {
            h5 {
                color: #152935;
                font-size: 14px;
                font-weight: 600;
            }
            p {
                color: #152935;
                font-size: 11px;
                font-weight: 400;
            }
        }
    }
    .insert-items {
        img {
            cursor: pointer;
            margin-right: 15px;
        }
    }
}
.model-search-input {
    width: 93% !important;
    position: relative;
    border-radius: 4px;
    &__field {
        background-color: #f5f5f5 !important;
        width: 100% !important;
        border: none !important;
        padding-right: 32px !important;
    }
    &__icon {
        z-index: 11;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
    }
    &__dropdown {
        .item {
            color: #000000 !important;
            // font-family: Poppins;
            font-size: 12px !important;
            font-weight: 400 !important;
            border-bottom: 1px solid #f5f5f5 !important;
        }
        .item:hover {
            background-color: #e4f7ff !important;

        }

       
    }
    
}
.condition-check {
    .option-label::before {
        margin-top: 2px !important;
    }
    .styled-checkbox:checked+label:after {
        top: 4px !important;
    }
}
.fnt-10 {
    font-size: 10px !important;
}
.drop-search-input {
    padding: .8rem !important;
    .text {
        color: #000000;
        // font-family: "Poppins Medium";
        font-size: 14px;
        font-weight: 500;
    }
    .menu {
        border: none !important;
    }
}

.schedule-date-input {
    width: 129px;
}
.schedule-time-input {
    width: 100px !important;
}
.schedule-modal {
    min-width: 575px !important;
   width: 100%;
}
.schedule-modal-container {
    padding: 35px 30px !important;
    
}
.remove-item  {
    opacity: 0;
    img {
        width: 10px;
        height: 10px;
        
    }
    span {
        margin-left: 6px;
        color: #00acf5;
        font-size: 12px;
        font-weight: 600;
    }
}
.cpy-input-wrp {
    border: none !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    background-color: #eff6fe;
    .cpy-img {
        cursor: pointer;
        width: 16px;
        height: 16px;
        margin-right: 12px;
    }
    input {
        border: none !important;
        background-color: transparent !important;
    }
}
.guest-remove-dropdown {
    .ui.selection.dropdown .menu>.item {
        padding-bottom: 10px !important;
    }
    .guest {
        border-radius: 4px;
        padding: 10px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .guest:hover {
        background-color: #f2f2f2 !important;
        .remove-item {
            cursor: pointer;
            opacity: 1;
        }
    }
}
.from-input-bgclr {
    border-radius: 4px;
    background-color: #f5f5f5 !important;
    border: none !important;
}

.cadidate-interview-tab {
    box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    background-color: #ffffff;
    max-width: 1105px;
    width: 100%;

    &__container {
        padding: 28px 32px;
        border-bottom: 2px solid #f5f5f5;
    }
    .tab-interview {
        padding: 32px 32px 13px 32px !important;

    }
    h4 {
        color: #373737;
        font-size: 16px;
        font-weight: 600;
    }
    h6 {
        color: #414242;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.42px;
    }
    .txt-13 {
        color: #131313;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
    }
    

}


.interview-details {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    padding : 24px 16px;
    border-radius: 8px;
    &--blue {
        border: 2px solid #429cf6;
        border-left-width: 6px;
        background-color: #fafcff;
    }
    &--orange {
        border: 2px solid #f8a849;
        border-left-width: 6px;
        background-color: #fffcf8;
    }
}
.interview-item  {
    margin-right: 36px;
    margin-bottom: 4px;
}
.time-txt {
   
    color: #4d4d4d;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    &--blue {
        position: relative;
        &::after {
            position: absolute;
            content: '';
            width: 10px;
            height: 10px;
            background-color: #419bf6;
            border-radius: 50%;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    
    &--orange {
        position: relative;
        &::after {
            position: absolute;
            content: '';
            width: 10px;
            height: 10px;
            background-color: #f8a849;
            border-radius: 50%;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
.interview-list  {
    padding-left: 40px;
    li.line {
        position: relative;
        &::before {
            position: absolute;
            content: '';
            width: 2px;
            height: 102%;
            // height: 112%;
            background: #dedede;
            left: -16px;
            top: 10px;
        }
    }
    li {
        padding-bottom: 30px;
    }
    // li:first-child {
    //     padding-bottom: 20px;
    // }
    // li:last-child {
    //     padding-top: 20px;
    //     padding-bottom: 28px;
    // }
   
}
.txt-btn {
    cursor: pointer;
    color: #cacaca;
    font-size: 10px;
    font-weight: 500;
    
    &--blue {
        margin-left: 20px;

        position: relative;
        &::after {
            position: absolute;
            content: '';
            width: 10px;
            height: 10px;
            background-color: #419bf6;
            border-radius: 2px;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &--orange {
        position: relative;
        margin-right: 10px;
        &::after {
            position: absolute;
            content: '';
            width: 10px;
            height: 10px;
            background-color: #f8a849;
            border-radius: 2px;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.vedio-dwnld {
    
    width: 124px;
    padding: 8px;
    border-radius: 4px;
    background-color: #09b4fe;
    &__head {
        color:#ffffff !important;
        font-size: 10px;
        font-weight: 600;
    }
    &__time {
        color:#ffffff;
        font-size: 10px;
        font-weight: 400;
    }

    .vedio-info {
        display: grid;
    }
    .icon-wrper {
        align-self: center;
        margin-left: 15px;
        img {
            width: 14px;
            height: 15px
        }
    }
}

//candidate notes
.candidate-notes {
    padding: 30px 25px 40px;
    box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    background-color: #ffffff;
    &__wrapper {
        display: flex;
        margin-right: 10px;
        margin-bottom: 32px;
        max-width: 341px;

        &:hover {
            .dots-info {
                display: none;
            }
        }
    }
    &__container {
        display: flex;
        flex-direction: column;
        
        border-radius: 4px;
        background-color: #eff6fe;  
        
    }
    &__head {
        flex: 1;
        padding: 16px;
        border-bottom: 1px solid  #e4eaf2  ;
    }
    &__profile {
        padding: 12px 16px 8px;
    }

    &__editwrp {
        box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        background-color: #ffffff;
        display: none;
        width: 341px;
        padding: 24px 16px;
        .head-note {
            padding-bottom: 10px;
            color: #000000 !important;
            font-size: 12px !important;
            font-weight: 500 !important;
        }
        textarea {
            height: 82px;
        }
    }
    
    .noted-head {
        color: #131313;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
    .profile-wrp {
        img {
            width: 32px;
            height: 32px;
            margin-right: 10px;
        }
        
    }
    .name {
        line-height: 1;
        color: #131313;
        font-size: 12px;
        font-weight: 500;
    }

    .date {
        color: #9ea2a7;
        font-size: 10px;
        font-weight: 500;
    }
}
#note-delteModel {
    .head-note {
        padding-bottom: 10px;
        color: #000000 !important;
        font-size: 12px !important;
        font-weight: 500 !important;
    }
}

.file-container-msg {
    display: flex;
    flex-wrap: wrap;
    .file-msg-img {
        margin-right: 24px;
    }
    .file-msg-wrp {
        align-items: center;
    }
}
.file-upload-msg {
    margin-bottom: 8px;
    padding: 24px 12px 18px 24px !important;
    margin-right: 24px;
    border-radius: 4px !important;
    width: 246px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.file__row {
    
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-center {
    max-width: 1095px;
    width: 100%;
    margin: 0 auto;
}